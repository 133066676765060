// src/legal/TermsOfService.js
import React from "react";
import LegalLayout from "./LegalLayout";

const Section = ({ title, children }) => (
  <div className="mb-8 text-center">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">{title}</h2>
    <div className="text-left">
      {children}
    </div>
  </div>
);

const List = ({ children }) => (
  <ul className="list-disc pl-6 mb-6 space-y-2 text-gray-600 max-w-2xl mx-auto text-left">
    {children}
  </ul>
);

const TermsOfService = () => {
  return (
    <LegalLayout title="Terms of Service">
      <p className="text-gray-600 mb-8 text-lg max-w-2xl mx-auto">
        Welcome to linksort.ai. These terms govern your use of our link summarization service.
      </p>

      <Section title="Service Usage">
        <List>
          <li>You can use our service with or without an account</li>
          <li>Anonymous usage is limited to browser-based storage</li>
          <li>You can convert anonymous data to an account at any time</li>
          <li>You are responsible for the content you submit for summarization</li>
        </List>
      </Section>

      <Section title="User Accounts">
        <p className="mb-6">
          We offer two types of usage:
        </p>
        <List>
          <li>Anonymous: Uses browser identification to save your data</li>
          <li>Google Sign-In: Allows access across devices and browsers</li>
        </List>
      </Section>

      <Section title="Data Usage">
        <p className="mb-6">
          We only collect and store information necessary for the service to function:
        </p>
        <List>
          <li>Submitted links and their summaries</li>
          <li>Basic account information (for logged-in users)</li>
          <li>Browser identifier (for anonymous users)</li>
        </List>
      </Section>

      <Section title="Acceptable Use">
        <p className="mb-6">You agree to:</p>
        <List>
          <li>Submit only links you have the right to access</li>
          <li>Not use the service for any illegal purposes</li>
          <li>Not attempt to circumvent any service limitations</li>
        </List>
      </Section>

      <Section title="Service Limitations">
        <p className="mb-6">We reserve the right to:</p>
        <List>
          <li>Modify or discontinue the service at any time</li>
          <li>Remove content that violates these terms</li>
          <li>Update these terms as needed</li>
        </List>
      </Section>

      <Section title="Subscription Terms">
        <List>
          <li>Subscriptions are billed monthly in advance</li>
          <li>You can cancel your subscription at any time through your account settings</li>
          <li>When you cancel, your subscription remains active until the end of the current billing period</li>
          <li>After the 7-day money-back guarantee period, no refunds will be issued for remaining unused time</li>
          <li>New subscribers have a 7-day money-back guarantee from the initial subscription date</li>
          <li>Subscription changes take effect at the next billing cycle</li>
        </List>
      </Section>

      <Section title="Refund Policy">
        <List>
          <li>We offer refunds within 7 days of purchase if you're not satisfied</li>
          <li>Refunds are processed through your original payment method</li>
          <li>Technical issues that prevent service usage are eligible for refunds</li>
          <li>Contact support@linksort.ai for refund requests</li>
        </List>
      </Section>

      <Section title="Contact">
        <p className="text-gray-600 max-w-2xl mx-auto">
          Questions about these terms:{" "}
          <a 
            href="mailto:support@linksort.ai"
            className="text-blue-600 hover:text-blue-800 underline"
          >
            support@linksort.ai
          </a>
        </p>
      </Section>
    </LegalLayout>
  );
};

export default TermsOfService;