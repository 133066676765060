// components/syncService.js
import axios from 'axios';
import Cookies from 'js-cookie';
import BrowserIdService from './browserIdService';
import { getCategoryFromIndexedDB } from './indexeddb';
import { createApiInstance } from './auth/axiosConfig';

// 初始化axios headers
BrowserIdService.setAxiosHeaders(axios);

// 创建一个队列来存储待同步的操作
let syncQueue = [];
let isSyncing = false;
let isOnline = navigator.onLine;

// 为每个操作添加重试计数器
const MAX_RETRIES = 2;  // 最大重试次数
const RETRY_DELAY = 5000;  // 重试延迟时间（毫秒）

// 处理同步队列
async function processSyncQueue() {
    if (isSyncing || syncQueue.length === 0 || !isOnline) return;
    
    isSyncing = true;
    const operation = syncQueue[0];
    
    try {
        const browserId = BrowserIdService.getBrowserId();
        const apiInstance = createApiInstance(); // 创建API实例
        
        // 添加请求前的数据验证
        if (operation.type === 'favorites' && !operation.data) {
            throw new Error('Missing data in favorites operation');
        }

        if (operation.type === 'categories') {
            // 添加详细的操作日志
            console.log('==== Processing Category Operation ====');
            console.log('Operation type:', operation.type);
            console.log('Operation action:', operation.operation);
            console.log('Operation data:', operation.data);

            // 只处理创建、删除和重命名操作
            if (operation.operation === 'create') {
                await apiInstance.post(
                    `${process.env.REACT_APP_API_URL}/sort/categories/`,
                    { name: operation.data.category },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Browser-ID': browserId
                        },
                        withCredentials: true
                    }
                );
            } else if (operation.operation === 'delete') {
                await apiInstance.delete(
                    `${process.env.REACT_APP_API_URL}/sort/categories/${encodeURIComponent(operation.data.category)}/`,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Browser-ID': browserId
                        },
                        withCredentials: true
                    }
                );
            } else if (operation.operation === 'rename') {
                await apiInstance.put(
                    `${process.env.REACT_APP_API_URL}/sort/categories/${encodeURIComponent(operation.data.oldName)}/`,
                    { new_name: operation.data.newName },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Browser-ID': browserId
                        },
                        withCredentials: true
                    }
                );
            }
            
            // 成功后移除该操作
            syncQueue.shift();
        } else {
            // 处理其他类型的同步操作
            console.log('Other sync operation:', operation);
            
            await apiInstance.post(
                `${process.env.REACT_APP_API_URL}/sort/sync_operation/`,
                operation,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Browser-ID': browserId
                    },
                    withCredentials: true
                }
            );
        }
        
        // 成功后移除该操作
        syncQueue.shift();
        
    } catch (error) {
        console.error('Sync operation failed:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
            
            // 特别处理 400 错误
            if (error.response.status === 400) {
                console.warn('Invalid data detected, removing from queue');
                syncQueue.shift();
                if (typeof operation.onFailure === 'function') {
                    operation.onFailure(error);
                }
                return;
            }
        }
        
        operation.retryCount = (operation.retryCount || 0) + 1;
        
        if (operation.retryCount >= MAX_RETRIES) {
            console.error(`Operation failed after ${MAX_RETRIES} retries, removing from queue:`, operation);
            syncQueue.shift();
            
            if (typeof operation.onFailure === 'function') {
                operation.onFailure(error);
            }
        } else {
            await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
        }
    } finally {
        isSyncing = false;
        
        if (syncQueue.length > 0) {
            processSyncQueue();
        }
    }
}

// 添加操作到同步队列
export function addToSyncQueue(operation, onFailure = null) {
    // 添加队列操作日志
    console.log('==== Adding to Sync Queue ====');
    console.log('Operation:', operation);
    console.log('Current queue length:', syncQueue.length);

    // 验证操作数据的完整性
    if (!operation.type || !operation.operation) {
        console.error('Invalid operation format:', operation);
        return;
    }

    // 特别处理收藏同步操作
    if (operation.type === 'favorites' && operation.operation === 'update') {
        // 确保 data 字段存在且包含必要的属性
        if (!operation.data || typeof operation.data !== 'object') {
            console.error('Invalid favorites data:', operation.data);
            return;
        }

        // 如果是数组，确保每个元素都有必要的字段
        if (Array.isArray(operation.data)) {
            operation.data = operation.data.filter(item => {
                return item && item.url && item.category;
            });
            
            // 如果过滤后数组为空，不添加到队列
            if (operation.data.length === 0) {
                console.warn('No valid favorites data after filtering');
                return;
            }
        } else {
            // 单个收藏项，确保至少有 url 和 category
            if (!operation.data.url || !operation.data.category) {
                console.error('Missing required fields in favorite:', operation.data);
                return;
            }
        }
    }

    // 如果是分类重命名操作，确保保存新的名称
    if (operation.type === 'categories' && operation.operation === 'update') {
        operation.data = {
            ...operation.data,
            category: operation.data.newName || operation.data.category
        };
        console.log('Updated operation data for rename:', operation.data);
    }
    
    if (onFailure) {
        operation.onFailure = onFailure;
    }
    
    syncQueue.push(operation);
    console.log('New queue length:', syncQueue.length);
    
    if (isOnline) {
        processSyncQueue();
    }
}

// 获取当前队列状态
export function getSyncQueueStatus() {
    return {
        queueLength: syncQueue.length,
        isSyncing,
        isOnline
    };
}

// 网络状态监控
window.addEventListener('online', () => {
    isOnline = true;
    processSyncQueue();
});

window.addEventListener('offline', () => {
    isOnline = false;
});