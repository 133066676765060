// components/categoryUtils.js
import axios from 'axios';
import { cosineSimilarity } from './similarity';
import { 
    getEmbeddingFromIndexedDB, 
    getCategoryEmbeddingFromIndexedDB, 
    getCategoriesFromIndexedDB, 
    deleteCategoryFromIndexedDB, 
    getFavoritesFromIndexedDB, 
    saveFavoritesToIndexedDB,
    saveCategoryToIndexedDB, 
    saveCategoryEmbeddingToIndexedDB,
    getCategoryFromIndexedDB  // 添加这个导入
} from './indexeddb'; // 从 indexeddb.js 中导入
import { createApiInstance } from './auth/axiosConfig';
import { handleApiResponse } from './Toast';
import Cookies from 'js-cookie';

let isSyncing = false;

// 处理分类比较逻辑
export async function handleCompareWithCategories(newEmbedding) {
    try {
        const storedCategories = await getCategoriesFromIndexedDB();  // 从 IndexedDB 获取分类列表
        let maxSimilarity = -1;
        let bestCategory = null;

        for (const category of storedCategories) {
            console.log("Comparing category object:", category);

            // 正确地引用 category 字段
            const categoryName = category.category;
            console.log("Comparing category:", categoryName);

            const urls = category.urls || [];

            if (urls.length > 0) {
                // 获取该分类下所有 URL 的嵌入向量，并计算与新 URL 嵌入的平均相似度
                const embeddings = await Promise.all(urls.map(url => getEmbeddingFromIndexedDB(url)));
                const validEmbeddings = embeddings.filter(embedding => embedding !== null);
                
                if (validEmbeddings.length > 0) {
                    const similarities = validEmbeddings.map(embedding => cosineSimilarity(newEmbedding, embedding));
                    const averageSimilarity = similarities.reduce((a, b) => a + b, 0) / similarities.length;
                    
                    console.log(`Average similarity with category ${categoryName}: ${averageSimilarity}`);
                    
                    // 更新最相似的分类
                    if (averageSimilarity > maxSimilarity) {
                        maxSimilarity = averageSimilarity;
                        bestCategory = { name: categoryName, similarity: averageSimilarity };
                    }
                }
            } else {
                // 如果该分类没有 URL 嵌入，则比较分类本身的嵌入
                const categoryEmbedding = await getCategoryEmbeddingFromIndexedDB(categoryName);  // 从 IndexedDB 获取分类嵌入
                if (categoryEmbedding) {
                    const similarity = cosineSimilarity(newEmbedding, categoryEmbedding);
                    console.log(`Similarity with category ${categoryName} embedding: ${similarity}`);
                    
                    if (similarity > maxSimilarity) {
                        maxSimilarity = similarity;
                        bestCategory = { name: categoryName, similarity: similarity };
                    }
                }
            }
        }

        if (bestCategory) {
            console.log(`Best matching category: ${bestCategory.name} with similarity: ${bestCategory.similarity}`);
        } else {
            console.log('No matching category found.');
        }

        return bestCategory;

    } catch (error) {
        console.error('Error comparing embeddings with categories:', error);
    }
}

// 从服务器生成分类嵌入
export async function generateCategoryEmbedding(categoryName, apiUrl, csrfToken, retryCount = 1) {
    for (let attempt = 1; attempt <= retryCount; attempt++) {
        try {
            const response = await axios.post(
                `${apiUrl}/sort/generateCategoryEmbedding/`,
                { category: categoryName },
                {
                    headers: {
                        'X-CSRFToken': csrfToken,
                        'Content-Type': 'application/json'
                    },
                    withCredentials: true
                }
            );

            const embedding = response.data.embedding;
            console.log(`Generated embedding for category ${categoryName}:`, embedding);
            return embedding;
        } catch (error) {
            console.error(`Attempt ${attempt} failed to generate category embedding:`, error);
            
            // 如果是服务器明确返回的错误（如参数错误），直接返回null不再重试
            if (error.response && error.response.status === 400) {
                console.error('Invalid category format, stopping retry');
                return null;
            }
            
            // 如果是最后一次尝试，则返回null
            if (attempt === retryCount) {
                console.error(`Failed to generate category embedding after ${retryCount} attempts`);
                return null;
            }
            
            // 如果不是最后一次尝试，等待短暂时间后重试
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
    }
}

// 添加创建分类的处理函数
export const handleCreateCategory = async (categoryName, categories) => {
    if (!categoryName.trim()) {
        handleApiResponse.error('Category name cannot be empty');
        return null;
    }

    if (categories.includes(categoryName.trim())) {
        handleApiResponse.error('Category already exists');
        return null;
    }

    try {
        const apiInstance = createApiInstance();
        
        // 创建分类并发送到服务器
        const response = await apiInstance.post('/sort/categories/', {
            name: categoryName.trim()
        });
        
        // 使用服务器返回的数据
        const categoryData = {
            category: categoryName.trim(),
            icon_url: response.data.icon_url || null,
            urls: []
        };
        
        // 保存到IndexedDB
        await saveCategoryToIndexedDB(categoryData);
        
        // 生成新分类的embedding
        const newCategoryEmbedding = await generateCategoryEmbedding(
            categoryName.trim(),
            process.env.REACT_APP_API_URL,
            Cookies.get('csrftoken')
        );
        
        if (newCategoryEmbedding) {
            await saveCategoryEmbeddingToIndexedDB(categoryName.trim(), newCategoryEmbedding);
        }
        
        handleApiResponse.success('Category created successfully');
        return categoryData;

    } catch (error) {
        handleApiResponse.error('Failed to create category');
        console.error('Error creating category:', error);
        return null;
    }
};

// 检查和修复缺失的 embeddings
export async function checkAndFixMissingEmbeddings() {
    if (isSyncing) return;
    
    try {
        isSyncing = true;
        console.log('Checking for categories with missing embeddings...');
        
        const categories = await getCategoriesFromIndexedDB();
        const categoriesNeedingEmbedding = categories.filter(cat => !cat.embedding);
        
        if (categoriesNeedingEmbedding.length > 0) {
            console.log(`Found ${categoriesNeedingEmbedding.length} categories missing embeddings:`, 
                categoriesNeedingEmbedding.map(cat => cat.category));
            
            for (const category of categoriesNeedingEmbedding) {
                console.log(`Generating embedding for category: ${category.category}`);
                
                const embedding = await generateCategoryEmbedding(
                    category.category,
                    process.env.REACT_APP_API_URL,
                    Cookies.get('csrftoken')
                );

                if (embedding) {
                    await saveCategoryEmbeddingToIndexedDB(category.category, embedding);
                    console.log(`Successfully generated and saved embedding for category: ${category.category}`);
                } else {
                    console.warn(`Failed to generate embedding for category: ${category.category}`);
                }
            }
            
            console.log('Finished processing categories with missing embeddings');
            return true;
        } else {
            console.log('No categories found with missing embeddings');
            return false;
        }
    } catch (error) {
        console.error('Error checking and fixing missing embeddings:', error);
        throw error;
    } finally {
        isSyncing = false;
    }
}

// 修改现有的 syncCategoriesFromServer 函数，在同步后调用检查函数
export async function syncCategoriesFromServer() {
    if (isSyncing) {
        console.log('🚫 Skipping sync - already in progress');
        return;
    }
    
    console.log('🔄 Starting syncCategoriesFromServer');
    
    try {
        isSyncing = true;
        const apiInstance = createApiInstance();
        const response = await apiInstance.get('/sort/categories/');
        console.log('📥 Server categories:', response.data.categories);

        const serverCategories = response.data.categories;
        if (!Array.isArray(serverCategories)) {
            console.error('❌ Invalid categories data:', serverCategories);
            return;
        }

        // 获取本地分类
        const localCategories = await getCategoriesFromIndexedDB();
        console.log('💾 Local categories:', localCategories);
        
        let categoriesChanged = false;
        
        // 1. 删除本地有但服务器没有的分类
        for (const localCat of localCategories) {
            if (!serverCategories.some(sc => sc.name === localCat.category)) {
                console.log(`🗑️ Removing local category ${localCat.category}`);
                await deleteCategoryFromIndexedDB(localCat.category, true);
                await updateFavoritesCategory(localCat.category, '');
                console.log(`Removed local category ${localCat.category} as it no longer exists on server`);
                categoriesChanged = true;

                // 触发特定的删除事件
                window.dispatchEvent(new CustomEvent('categoryDeleted', {
                    detail: {
                        category: localCat.category
                    }
                }));
            }
        }

        // 2. 更新或添加服务器上存在的分类
        for (const serverCat of serverCategories) {
            const localCat = localCategories.find(local => local.category === serverCat.name);
            
            if (!localCat || localCat.icon_url !== serverCat.icon_url) {
                console.log(`📝 Updating/Adding category ${serverCat.name}`);
                const dataToStore = {
                    category: serverCat.name,
                    icon_url: serverCat.icon_url,
                    urls: []
                };
                await saveCategoryToIndexedDB(dataToStore);
                console.log(`Updated/Added category ${serverCat.name} from server`);
                categoriesChanged = true;
            }
        }

        // 如果分类有变化，触发更新事件
        if (categoriesChanged) {
            console.log('🔄 Categories changed, getting updated list...');
            const updatedCategories = await getCategoriesFromIndexedDB();
            console.log('📤 Dispatching categoriesUpdated event with:', updatedCategories);
            
            window.dispatchEvent(new CustomEvent('categoriesUpdated', {
                detail: {
                    categories: updatedCategories
                }
            }));
        } else {
            console.log('ℹ️ No category changes detected');
        }

        return true;
    } catch (error) {
        console.error('❌ Error syncing categories:', error);
        throw error;
    } finally {
        isSyncing = false;
    }
}

// 添加一个初始化函数，可以在应用启动时调用
export async function initializeCategories() {
    try {
        console.log('Initializing categories...');
        
        // 先同步服务器数据
        await syncCategoriesFromServer();
        
        // 检查是否所有分类都有 embedding
        const needsFixing = await checkAndFixMissingEmbeddings();
        
        if (needsFixing) {
            console.log('Successfully fixed missing category embeddings');
        } else {
            console.log('All categories are properly initialized');
        }
        
        return true;
    } catch (error) {
        console.error('Error initializing categories:', error);
        throw error;
    }
}

// 更使用特定分类的收藏项
export const updateFavoritesCategory = async (oldCategory, newCategory) => {
    try {
        const favorites = await getFavoritesFromIndexedDB();
        const updatedFavorites = favorites.map(fav => {
            if (fav.category === oldCategory) {
                return { ...fav, category: newCategory };
            }
            return fav;
        });

        await saveFavoritesToIndexedDB(updatedFavorites);
        return true;
    } catch (error) {
        console.error('Error updating favorites categories:', error);
        return false;
    }
};

export const handleDeleteCategory = async (categoryName) => {
    try {
        const apiInstance = createApiInstance();
        
        // 先检查服务器是否存在该分类
        try {
            const response = await apiInstance.get('/sort/categories/');
            const serverCategories = response.data.categories;
            const categoryExists = serverCategories.some(cat => cat.name === categoryName);
            
            if (!categoryExists) {
                // 如果服务器上不存在该分类，只删除本地数据
                await deleteCategoryFromIndexedDB(categoryName, true);
                await updateFavoritesCategory(categoryName, '');
                handleApiResponse.success('Category deleted locally');
                return true;
            }
        } catch (checkError) {
            console.error('Error checking category existence:', checkError);
        }

        // 使用 encodeURIComponent 对分类名进行编码
        const encodedCategory = encodeURIComponent(categoryName);
        
        // 发送删除请求
        await apiInstance.delete(`/sort/categories/${encodedCategory}/`);
        
        // 删除本地数据
        await deleteCategoryFromIndexedDB(categoryName, true);
        await updateFavoritesCategory(categoryName, '');
        
        handleApiResponse.success('Category deleted successfully');
        return true;
    } catch (error) {
        console.error('Error deleting category:', error);
        handleApiResponse.error('Failed to delete category');
        return false;
    }
};

export const handleRenameCategory = async (oldName, newName) => {
    if (!newName.trim()) {
        handleApiResponse.error('Category name cannot be empty');
        return { success: false };
    }

    try {
        const apiInstance = createApiInstance();
        
        // 1. 发送重命名请求到服务器
        const response = await apiInstance.put(`/sort/categories/${encodeURIComponent(oldName)}/`, {
            new_name: newName.trim()
        });

        // 2. 获取旧分类的数据
        const oldEmbedding = await getCategoryEmbeddingFromIndexedDB(oldName);
        
        // 3. 删除旧分类
        await deleteCategoryFromIndexedDB(oldName);
        
        // 4. 创建新分类，使用服务器返回的图标URL
        const categoryData = {
            category: newName.trim(),
            embedding: oldEmbedding?.embedding || null,
            icon_url: response.data.category.icon_url || null
        };
        await saveCategoryToIndexedDB(categoryData);

        // 5. 更新所有使用该分类的收藏项
        const favorites = await getFavoritesFromIndexedDB();
        const updatedFavorites = favorites.map(fav => ({
            ...fav,
            category: fav.category === oldName ? newName.trim() : fav.category
        }));
        await saveFavoritesToIndexedDB(updatedFavorites);

        handleApiResponse.success('Category renamed successfully');
        
        return {
            success: true,
            category: {
                name: newName.trim(),
                icon_url: response.data.category.icon_url
            }
        };
    } catch (error) {
        console.error('Error renaming category:', error);
        handleApiResponse.error('Failed to rename category');
        return { success: false };
    }
};

// 更新收藏项的分类
export const updateFavoriteCategory = async (url, newCategory) => {
    try {
        const apiInstance = createApiInstance();
        
        // 发送更新请求到后端
        await apiInstance.post('/sort/update_favorite_category/', {
            url,
            new_category: newCategory
        });

        // 更新本地 IndexedDB
        const favorites = await getFavoritesFromIndexedDB();
        const updatedFavorites = favorites.map(fav => {
            if (fav.url === url) {
                return { ...fav, category: newCategory };
            }
            return fav;
        });
        
        await saveFavoritesToIndexedDB(updatedFavorites);
        
        // 触发更新事件
        window.dispatchEvent(new CustomEvent('favoriteChanged', {
            detail: {
                type: 'update',
                url,
                newCategory
            }
        }));

        // 立即更新 FavoritesList 组件的状态
        window.dispatchEvent(new CustomEvent('favoritesUpdated', {
            detail: {
                favorites: updatedFavorites
            }
        }));
        
        handleApiResponse.success('Category updated successfully');
        return true;
    } catch (error) {
        console.error('Error updating favorite category:', error);
        handleApiResponse.error('Failed to update category');
        return false;
    }
};
