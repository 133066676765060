// components/auth/UserMenu.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Settings, LogOut, Crown, Calendar, CreditCard } from 'lucide-react';
import { useAuth } from './AuthContext';
import '../../css/UserMenu.css';

const UserMenu = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    };

    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        setIsMenuOpen(false);
      }
    };

    if (isMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isMenuOpen]);

  if (!user) {
    return null;
  }

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    });
  };

  const getRemainingDays = (endDate) => {
    if (!endDate) return 0;
    const end = new Date(endDate);
    const now = new Date();
    const diffTime = end.getTime() - now.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleAccountSettings = () => {
    navigate('/account');
    setIsMenuOpen(false);
  };

  const handleUpgrade = () => {
    navigate('/pricing');
    setIsMenuOpen(false);
  };

  return (
    <div className="user-menu-container" ref={menuRef}>
      <button
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        className="user-menu-trigger"
        aria-expanded={isMenuOpen}
        aria-haspopup="true"
      >
        <div className={`user-avatar ${user?.is_premium ? 'premium' : ''}`}>
          {user?.email && user.email[0].toUpperCase()}
          {user?.is_premium && (
            <Crown className="premium-badge" size={12} />
          )}
        </div>
        <span className="user-email" title={user?.email}>
          {user?.email}
        </span>
      </button>

      <div className={`user-menu-dropdown ${isMenuOpen ? 'active' : ''}`}>
        <div className="user-menu-content">
          <div className="user-menu-header">
            <div className="user-menu-email" title={user?.email}>
              {user?.email}
            </div>
            {user?.is_premium && (
              <>
                <div className="premium-status">
                  <Crown className="premium-icon" size={14} />
                  <span>Pro Member</span>
                </div>
                <div className="subscription-info">
                  <div className="subscription-date">
                    <Calendar className="subscription-icon" size={14} />
                    <span>Expires: {formatDate(user?.subscription_end)}</span>
                  </div>
                  <div className="subscription-remaining">
                    {getRemainingDays(user?.subscription_end)} days remaining
                  </div>
                </div>
              </>
            )}
            
            {!user?.is_premium && (
              <div className="upgrade-prompt">
                <button onClick={handleUpgrade} className="upgrade-button">
                  <Crown className="menu-icon" size={14} />
                  <span>Upgrade to Pro</span>
                </button>
              </div>
            )}
          </div>
          
          <div className="menu-group">
            <button
              onClick={handleAccountSettings}
              className="menu-item"
            >
              <Settings className="menu-icon" aria-hidden="true" />
              <span>Settings</span>
            </button>
            
            {user?.is_premium && (
              <a
                href={process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL}
                target="_blank"
                rel="noopener noreferrer"
                className="menu-item"
              >
                <CreditCard className="menu-icon" aria-hidden="true" />
                <span>Manage Subscription</span>
              </a>
            )}
          </div>
          
          <div className="menu-group">
            <button
              onClick={handleLogout}
              className="menu-item menu-item-danger"
            >
              <LogOut className="menu-icon" aria-hidden="true" />
              <span>Sign Out</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;