// src/components/Pricing.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Check } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';
import '../css/Pricing.css';
import { createApiInstance } from './auth/axiosConfig';
import { useAuth } from './auth/AuthContext';
import { AUTH_ROUTES } from './auth/constants';

// 初始化 Stripe
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const PricingPage = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const [selectedPlan] = useState('monthly'); // 只提供月付选项

  const handleUpgrade = async () => {
    if (!isAuthenticated) {
      navigate(AUTH_ROUTES.LOGIN, { 
        state: { from: '/pricing' },
        replace: true 
      });
      return;
    }

    setLoading(true);
    try {
      console.log('Using price ID:', process.env.REACT_APP_STRIPE_PRICE_ID);
      
      const apiInstance = createApiInstance();
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Stripe failed to initialize');
      }
      
      const priceId = process.env.REACT_APP_STRIPE_PRICE_ID;
      if (!priceId) {
        throw new Error('Price ID is not configured');
      }

      const response = await apiInstance.post('/sort/create-checkout-session/', { priceId });

      if (!response?.data?.sessionId) {
        throw new Error('Invalid response from server');
      }

      const { sessionId } = response.data;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Payment error:', {
        message: error.message,
        response: error.response?.data,
        status: error.response?.status
      });
      
      if (error.response?.status === 401) {
        navigate(AUTH_ROUTES.LOGIN, { 
          state: { from: '/pricing' },
          replace: true 
        });
      } else if (error.response?.status === 403) {
        alert('Session expired. Please refresh the page.');
      } else {
        alert(`Payment failed: ${error.message || 'Please try again later.'}`);
      }
    } finally {
      setLoading(false);
    }
  };

  const features = [
    'Unlimited summaries',
    'Cross-device sync',
    'Priority support',
    'Advanced AI features',
    'Custom categories'
  ];

  return (
    <div className="pricing-container">
      <div className="pricing-header">
        <h1>Pricing</h1>
        <p>Choose a plan to buy credits.</p>
      </div>

      <div className="pricing-toggle">
        <button className="toggle-button active">Monthly</button>
      </div>

      <div className="pricing-cards">
        {/* Free Plan */}
        <div className="pricing-card">
          <div className="card-header">
            <h2>Basic</h2>
            <div className="price">
              <span className="currency">$</span>
              <span className="amount">0</span>
              <span className="period">/month</span>
            </div>
          </div>
          <div className="card-features">
            <ul>
              <li>
                <Check className="check-icon" />
                <span>5 summaries per day</span>
              </li>
              <li>
                <Check className="check-icon" />
                <span>Basic AI features</span>
              </li>
              <li>
                <Check className="check-icon" />
                <span>Single device</span>
              </li>
            </ul>
          </div>
          <button 
            className="upgrade-button outline"
            onClick={() => navigate('/register')}
          >
            Get Started
          </button>
        </div>

        {/* Pro Plan */}
        <div className="pricing-card pro">
          <div className="popular-badge">Popular</div>
          <div className="card-header">
            <h2>Pro</h2>
            <div className="price">
              <span className="currency">$</span>
              <span className="amount">5</span>
              <span className="period">/month</span>
            </div>
          </div>
          <div className="card-features">
            <ul>
              {features.map((feature, index) => (
                <li key={index}>
                  <Check className="check-icon" />
                  <span>{feature}</span>
                </li>
              ))}
            </ul>
          </div>
          <button 
            className="upgrade-button primary"
            onClick={handleUpgrade}
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Upgrade Plan'}
          </button>
        </div>
      </div>

      <div className="pricing-faq">
        <p className="money-back">No extra fees, no hidden charges—cancel anytime</p>
        <p className="support-text">
          Questions? Contact our support team at{' '}
          <a href="mailto:support@linksort.ai">support@linksort.ai</a>
        </p>
      </div>
    </div>
  );
};

export default PricingPage; 