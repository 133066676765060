// legal/CookieConsent.js

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import '../css/CookieConsent.css';

const CookieConsent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const COOKIE_CONSENT_NAME = 'linksort_cookie_consent';
  const COOKIE_EXPIRES = 365;

  useEffect(() => {
    const hasConsent = Cookies.get(COOKIE_CONSENT_NAME);
    if (!hasConsent) {
      setModalIsOpen(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set(COOKIE_CONSENT_NAME, 'true', { expires: COOKIE_EXPIRES, sameSite: 'Lax' });
    setModalIsOpen(false);
  };

  const handleClose = () => {
    Cookies.set(COOKIE_CONSENT_NAME, 'true', { expires: COOKIE_EXPIRES, sameSite: 'Lax' });
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={handleClose}
      overlayClassName="cookie-modal-overlay"
      className="cookie-modal-content"
      contentLabel="Cookie Consent Dialog"
    >
      <div className="cookie-modal-container">
        <button
          onClick={handleClose}
          className="cookie-close-button"
          aria-label="Close cookie notice"
        >
          ✖️
        </button>
        
        <div className="cookie-content">
          <div className="cookie-icon">🍪</div>
          <p className="cookie-description">
            Hey there! We use cookies to make your experience awesome! They help our site work smoothly and let us understand how we can make things even better for you ✨
          </p>
          
          <div className="cookie-buttons">
            <a href="/privacy-policy" className="cookie-link">
              Privacy Policy
            </a>
            <button
              onClick={handleAccept}
              className="cookie-accept-button"
            >
              Got it
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CookieConsent;