// src/components/payment/PaymentError.js

import React from 'react';
import { useNavigate } from 'react-router-dom';

const PaymentError = () => {
  const navigate = useNavigate();

  return (
    <div className="payment-error-container">
      <div className="payment-error-content">
        <div className="error-icon">✕</div>
        <h1>Payment Failed</h1>
        <p>Sorry, there was an error processing your payment.</p>
        <button onClick={() => navigate('/pricing')}>
          Try Again
        </button>
      </div>
    </div>
  );
};

export default PaymentError; 