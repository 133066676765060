// src/components/payment/PaymentSuccess.js

import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../../css/Payment.css';  // 建议创建专门的样式文件

const PaymentSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  
  useEffect(() => {
    const sessionId = searchParams.get('session_id');
    
    // 可以在这里验证支付状态
    const verifyPayment = async () => {
      try {
        const response = await fetch(`/sort/verify-payment/?session_id=${sessionId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Payment verification failed');
        }
      } catch (error) {
        console.error('Payment verification error:', error);
        // 可以重定向到错误页面
        navigate('/payment/error');
      }
    };

    if (sessionId) {
      verifyPayment();
    }

    // 3秒后重定向到主应用
    const timer = setTimeout(() => {
      navigate('/app');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate, searchParams]);

  return (
    <div className="payment-success-container">
      <div className="payment-success-content">
        <div className="success-icon">✓</div>
        <h1>Payment Successful!</h1>
        <p>Thank you for upgrading to Pro!</p>
        <p className="redirect-message">
          You will be redirected to the app in a few seconds...
        </p>
      </div>
    </div>
  );
};

export default PaymentSuccess; 