// src/legal/PrivacyPolicy.js
import React from "react";
import LegalLayout from "./LegalLayout";

const Section = ({ title, children }) => (
  <div className="mb-8 text-center">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">{title}</h2>
    <div className="text-left">
      {children}
    </div>
  </div>
);

const List = ({ children }) => (
  <ul className="list-disc pl-6 mb-6 space-y-2 text-gray-600 max-w-2xl mx-auto text-left">
    {children}
  </ul>
);

const PrivacyPolicy = () => {
  return (
    <LegalLayout title="Privacy Policy">
      <p className="text-gray-600 mb-8 text-lg max-w-2xl mx-auto">
        At linksort.ai, we prioritize your privacy and minimize data collection. 
        This policy explains what limited information we collect, how we use it, and our cookie practices.
      </p>

      <Section title="Information We Collect">
        <p className="text-gray-600 mb-4 max-w-2xl mx-auto">
          We collect only essential information needed to provide our service:
        </p>
        <List>
          <li>For registered users: Email address and password</li>
          <li>For anonymous users: A browser identifier to save your summaries</li>
          <li>Links you submit for summarization</li>
          <li>Your summaries and categorizations</li>
        </List>
      </Section>

      <Section title="What We Don't Collect">
        <List>
          <li>IP addresses</li>
          <li>Device information</li>
          <li>Location data</li>
        </List>
      </Section>

      <Section title="How We Use Information">
        <p className="text-gray-600 mb-4">We use your information solely to:</p>
        <List>
          <li>Authenticate your account</li>
          <li>Provide link summarization services</li>
          <li>Save your summaries for future reference</li>
          <li>Associate summaries with your account (if registered)</li>
        </List>
      </Section>

      <Section title="Data Storage">
        <p className="text-gray-600 mb-4">
          For registered users, your data is stored securely and associated with your account.
          For anonymous users, data is stored using a browser identifier and can be accessed only from the same browser.
          You can delete your data at any time.
        </p>
      </Section>

      <Section title="Third-Party Services">
        <p className="text-gray-600 mb-4">We use:</p>
        <List>
          <li>Email service for account verification and password reset</li>
          <li>AI APIs for summarization (only processes the links you submit)</li>
        </List>
      </Section>

      <Section title="Your Rights">
        <p className="text-gray-600 mb-4">You have the right to:</p>
        <List>
          <li>Use our service without creating an account</li>
          <li>Access and delete your data at any time</li>
          <li>Convert an anonymous account to a registered account</li>
        </List>
      </Section>

      <Section title="Cookies and Local Storage">
        <p className="text-gray-600 mb-4 max-w-2xl mx-auto">
          We use cookies and local storage to enhance your browsing experience. These are small text files stored on your device that help make our website work more efficiently.
        </p>
        <p className="text-gray-600 mb-4">We use the following types of cookies:</p>
        <List>
          <li>
            <span className="font-medium">Essential Cookies:</span>{" "}
            Required for the website to function (authentication, security tokens)
          </li>
          <li>
            <span className="font-medium">Functional Storage:</span>{" "}
            Stores your preferences and summaries for anonymous users
          </li>
        </List>
        <p className="text-gray-600 mb-4">
          You can control cookies through your browser settings. Disabling cookies may limit some website functionality.
        </p>
      </Section>

      <Section title="Updates to This Policy">
        <p className="text-gray-600 mb-4">
          We may update this Privacy Policy from time to time to reflect changes in our practices 
          or for operational, legal, or regulatory reasons. We encourage you to periodically 
          review this page for the latest information.
        </p>
      </Section>

      <Section title="Contact">
        <p className="text-gray-600 max-w-2xl mx-auto">
          For privacy-related questions:{" "}
          <a 
            href="mailto:support@linksort.ai"
            className="text-blue-600 hover:text-blue-800 underline"
          >
            support@linksort.ai
          </a>
        </p>
      </Section>

      <Section title="Payment Information">
        <p className="text-gray-600 mb-4">For paid subscriptions:</p>
        <List>
          <li>Payment processing is handled securely by Stripe</li>
          <li>We don't store your credit card information</li>
          <li>Billing information is used only for payment processing</li>
          <li>Subscription status is stored to provide service access</li>
        </List>
      </Section>

      <Section title="Subscription Data">
        <List>
          <li>We maintain records of your subscription status</li>
          <li>Payment history is available in your account</li>
          <li>You can manage your subscription through the customer portal</li>
          <li>Cancellation data is used to improve our service</li>
        </List>
      </Section>
    </LegalLayout>
  );
};

export default PrivacyPolicy;

