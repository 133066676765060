// src/components/auth/constants.js
export const AUTH_ROUTES = {
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_EMAIL: '/verify-email',
  ACCOUNT_SETTINGS: '/account',
  PASSWORD_RESET: '/accounts/password/reset',
  PASSWORD_CHANGE: '/accounts/password/change',
  EMAIL_MANAGEMENT: '/account/settings/emails',
  EMAIL_VERIFIED_SUCCESS: '/email-verified-success',  
  EMAIL_VERIFIED_ERROR: '/email-verified-error',
  CONFIRM_EMAIL: '/accounts/confirm-email/:key'
};

export const AUTH_CONFIG = {
  endpoints: {
    login: '/sort/login/',
    register: '/sort/signup/',
    logout: '/accounts/logout/',
    userInfo: '/sort/auth/user-info/',
    verifyEmail: '/accounts/confirm-email/',
    resendVerification: '/sort/auth/resend-verification/',
    passwordReset: '/accounts/password/reset/',
    passwordChange: '/accounts/password/change/',
    emails: '/sort/auth/emails/',
    addEmail: '/sort/auth/emails/add/',
    removeEmail: '/sort/auth/emails/remove/',
    makePrimaryEmail: '/sort/auth/emails/make-primary/',
  },
  routes: {
    VERIFY_EMAIL: AUTH_ROUTES.VERIFY_EMAIL,
    EMAIL_VERIFIED_SUCCESS: AUTH_ROUTES.EMAIL_VERIFIED_SUCCESS,
    EMAIL_VERIFIED_ERROR: AUTH_ROUTES.EMAIL_VERIFIED_ERROR,
  },
  cookies: {
    csrf: 'csrftoken'
  }
};

export const ERROR_MESSAGES = {
  LOGIN_FAILED: 'Login failed. Please check your credentials and try again.',
  REGISTER_FAILED: 'Registration failed. Please try again.',
  NETWORK_ERROR: 'A network error occurred. Please check your connection and try again.',
  VERIFICATION_REQUIRED: 'Please verify your email address to continue.',
  INVALID_CREDENTIALS: 'Invalid email or password.',
  SERVER_ERROR: 'Server error. Please try again later.',
  EMAIL_VERIFICATION_FAILED: 'Email verification failed. Please try again or request a new verification link.',
  EMAIL_VERIFICATION_EXPIRED: 'Verification link has expired. Please request a new one.',
  EMAIL_VERIFICATION_SUCCESS: 'Email successfully verified! You can now use all features of LinkSort.',
};

export const EMAIL_VERIFICATION_STATES = {
  PENDING: 'pending',
  SUCCESS: 'success',
  ERROR: 'error',
  EXPIRED: 'expired'
};

export const loginValidation = {
  email: {
    required: 'Email is required',
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Please enter a valid email'
    }
  },
  password: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters'
    }
  }
};

export const registerValidation = {
  email: {
    required: 'Email is required',
    pattern: {
      value: /\S+@\S+\.\S+/,
      message: 'Please enter a valid email'
    }
  },
  password1: {
    required: 'Password is required',
    minLength: {
      value: 8,
      message: 'Password must be at least 8 characters'
    }
  },
  password2: {
    required: 'Please confirm your password',
    validate: (value, formValues) => 
      value === formValues.password1 || 'Passwords do not match'
  }
};

export const defaultFormValues = {
  login: {
    email: '',
    password: '',
    remember: false
  },
  register: {
    email: '',
    password1: '',
    password2: ''
  }
};

export const formOptions = {
  login: {
    defaultValues: defaultFormValues.login,
    mode: 'onBlur',
    reValidateMode: 'onChange'
  },
  register: {
    defaultValues: defaultFormValues.register,
    mode: 'onBlur',
    reValidateMode: 'onChange'
  }
};