// src/components/CategorySelector.js

import React, { useState } from 'react';

const CategorySelector = ({ 
    currentCategory, 
    categories, 
    onSelect, 
    onClose,
    handleCreateCategory 
}) => {
    const [newCategoryName, setNewCategoryName] = useState('');
    const [isCreating, setIsCreating] = useState(false);

    const handleCreate = async () => {
        if (!newCategoryName.trim()) return;
        
        const result = await handleCreateCategory(newCategoryName);
        if (result) {
            onSelect(newCategoryName);
            setNewCategoryName('');
            setIsCreating(false);
        }
    };

    const handleOverlayClick = (e) => {
        e.stopPropagation();
    };

    return (
        <div 
            className="category-selector-overlay" 
            onClick={handleOverlayClick}
        >
            <div>
                {categories.map(category => {
                    // 确保从对象中获取正确的属性
                    const categoryName = typeof category === 'string' ? category : category.name || category.category;
                    const iconUrl = typeof category === 'string' ? null : category.icon_url;
                    
                    return (
                        <div
                            key={categoryName}
                            className={`category-selector-item ${currentCategory === categoryName ? 'active' : ''}`}
                            onClick={() => onSelect(categoryName)}
                        >
                            {iconUrl && (
                                <img 
                                    src={iconUrl} 
                                    alt=""
                                    className="category-selector-icon"
                                    onError={(e) => e.target.style.display = 'none'}
                                />
                            )}
                            <span className="category-selector-name">{categoryName}</span>
                        </div>
                    );
                })}
            </div>
            
            {isCreating ? (
                <div className="category-selector-item">
                    <input
                        type="text"
                        value={newCategoryName}
                        onChange={e => setNewCategoryName(e.target.value)}
                        placeholder="New category name"
                        autoFocus
                        className="category-edit-input"
                    />
                    <div className="category-edit-actions">
                        <button className="confirm-edit-btn" onClick={handleCreate}>
                            Create
                        </button>
                        <button className="cancel-edit-btn" onClick={() => setIsCreating(false)}>
                            Cancel
                        </button>
                    </div>
                </div>
            ) : (
                <div 
                    className="category-selector-new"
                    onClick={() => setIsCreating(true)}
                >
                    + Create new category
                </div>
            )}
        </div>
    );
};

export default CategorySelector; 