// src/components/auth/PasswordChange.js

import React from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { createApiInstance } from '../auth/axiosConfig';
import { handleApiResponse } from '../Toast';

const PasswordChange = () => {
  const { register, handleSubmit, reset, formState: { errors }, getValues } = useForm();
  const apiInstance = createApiInstance();

  const onSubmit = async (data) => {
    try {
      await apiInstance.post('/sort/auth/password/change/', data);
      handleApiResponse.success('Password changed successfully');
      reset();
    } catch (error) {
      handleApiResponse.error(error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Change Password</h1>
      
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4 max-w-md">
        <div>
          <label className="block mb-2">Current Password:</label>
          <input
            type="password"
            {...register('old_password', { required: 'Current password is required' })}
            className="form-input w-full"
            placeholder="Enter current password"
          />
          {errors.old_password && (
            <p className="text-red-500 text-sm mt-1">{errors.old_password.message}</p>
          )}
        </div>

        <div>
          <label className="block mb-2">New Password:</label>
          <input
            type="password"
            {...register('new_password1', {
              required: 'New password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters'
              }
            })}
            className="form-input w-full"
            placeholder="Enter new password"
          />
          {errors.new_password1 && (
            <p className="text-red-500 text-sm mt-1">{errors.new_password1.message}</p>
          )}
        </div>

        <div>
          <label className="block mb-2">Confirm New Password:</label>
          <input
            type="password"
            {...register('new_password2', {
              required: 'Please confirm your new password',
              validate: value => 
                value === getValues('new_password1') || 'Passwords do not match'
            })}
            className="form-input w-full"
            placeholder="Confirm new password"
          />
          {errors.new_password2 && (
            <p className="text-red-500 text-sm mt-1">{errors.new_password2.message}</p>
          )}
        </div>

        <div className="flex items-center gap-4">
          <button type="submit" className="btn-primary">
            Change Password
          </button>
          <Link to="/accounts/password/reset/" className="text-blue-600 hover:underline">
            Forgot Password?
          </Link>
        </div>
      </form>
    </div>
  );
};

export default PasswordChange; 