// src/components/payment/BillingRedirect.js

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const BillingRedirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to Stripe Customer Portal
    window.location.href = process.env.REACT_APP_STRIPE_CUSTOMER_PORTAL_URL;
    
    // Fallback navigation if redirect fails
    const fallbackTimer = setTimeout(() => {
      navigate('/account');
    }, 3000);

    return () => clearTimeout(fallbackTimer);
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center min-h-[50vh]">
      <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mb-4"></div>
      <h2 className="text-xl font-semibold text-gray-700 mb-2">Redirecting to billing portal...</h2>
      <p className="text-gray-500">You will be redirected to Stripe's secure billing portal.</p>
    </div>
  );
};

export default BillingRedirect; 