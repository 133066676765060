// App.js
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import './App.css';
import Header from './components/Header';
import LandingPage from './components/LandingPage';
import Features from './components/Features';
import HelpCenter from './components/HelpCenter';
import Contact from './components/Contact';
import TermsOfService from './legal/TermsOfService';
import PrivacyPolicy from './legal/PrivacyPolicy';
import RefundPolicy from './legal/RefundPolicy';
import CookieConsent from './components/CookieConsent';
import Modal from 'react-modal';
import Footer from './components/Footer';
import MainLayout from './components/MainLayout';
import { getFavoritesFromIndexedDB } from './components/indexeddb';
import { syncCategoriesFromServer } from './components/categoryUtils';
import ChatPage from './components/ChatPage';
import { AuthProvider, ProtectedRoute, useAuth } from './components/auth/AuthContext';
import AuthRoute from './components/auth/AuthRoute';
import {
    LoginForm,
    RegisterForm,
    VerifyEmail,
    AccountSettings,
    EmailVerifiedSuccess,
    EmailVerifiedError,
    EmailConfirmation,
    PasswordChange,
} from './components/auth';
import ErrorBoundary from './components/ErrorBoundary';
import { createApiInstance, setupCSRF } from './components/auth/axiosConfig';
import { ToastProvider, handleApiResponse } from './components/Toast';
import PricingPage from './components/Pricing';
import { PaymentSuccess } from './components/payment';
import PaymentError from './components/payment/PaymentError';
import { BillingRedirect } from './components/payment';
import { AUTH_ROUTES } from './components/auth/constants';
import { processLink } from './components/LinkProcessor';

const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
};

function App() {
    Modal.setAppElement('#root');
    
    return (
        <Router>
            <AuthProvider>
                <ErrorBoundary>
                    <div className="App">
                        <AppRoutes />
                        <CookieConsent />
                        <ToastProvider />
                    </div>
                </ErrorBoundary>
            </AuthProvider>
        </Router>
    );
}

// 修改 AppRoutes 组件
const AppRoutes = () => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // 检查是否在首页
        if (location.pathname === '/') {
            // 只检查 loginStatus
            const loginStatus = localStorage.getItem('loginStatus');
            
            if (loginStatus === 'loggedIn') {
                console.log('🔄 User is logged in, redirecting to /app');
                navigate('/app');
            }
        }
    }, [location.pathname, navigate]);

    return <AppContent />;
};

const AppContent = () => {
    const location = useLocation();
    const { loading: authLoading, isInitialized } = useAuth();
    const [url, setUrl] = useState('');
    const [summary, setSummary] = useState('');       
    const [keyPoints, setKeyPoints] = useState([]);   
    const [category, setCategory] = useState('');     
    const [embedding, setEmbedding] = useState(null);
    const [linkLoading, setLinkLoading] = useState(false);
    const [favorites, setFavorites] = useState([]);
    const [filteredFavorites, setFilteredFavorites] = useState([]);
    const [categories, setCategories] = useState([]);
    const [lastSyncTime, setLastSyncTime] = useState(0);
    const [expandedItemUrl, setExpandedItemUrl] = useState(null);
    const [processingSteps, setProcessingSteps] = useState([]);
    
    const apiUrl = process.env.REACT_APP_API_URL;

    const apiInstance = useMemo(() => createApiInstance(apiUrl), [apiUrl]);

    // 修改同步处理函数，区分自动和手动触发
    const handleSync = useCallback(async (isManualTrigger = false) => {
        const now = Date.now();
        
        // 只有自动触发的同步才检查时间间隔
        if (!isManualTrigger && now - lastSyncTime < 60000) {
            console.log('⏭️ Skipping auto sync - too soon since last sync');
            return;
        }

        console.log(`🔄 Triggering ${isManualTrigger ? 'manual' : 'auto'} sync`);
        await syncCategoriesFromServer();
        setLastSyncTime(now);
    }, [lastSyncTime]);

    // 监听需要同步的事件
    useEffect(() => {
        const handleFavoriteChange = (event) => {
            console.log('🔄 Favorite changed event received');
            handleSync(true);
        };

        const handleUserLogin = () => {
            console.log('👤 User login event received');
            handleSync(true);
        };

        const handleCategoriesUpdate = (event) => {
            console.log('📋 Categories update event received:', event.detail.categories);
            setCategories(event.detail.categories);
            console.log('📋 Categories state updated to:', event.detail.categories);
        };

        console.log('🎯 Setting up event listeners');
        window.addEventListener('favoriteChanged', handleFavoriteChange);
        window.addEventListener('userLoggedIn', handleUserLogin);
        window.addEventListener('categoriesUpdated', handleCategoriesUpdate);
        
        return () => {
            console.log('🎯 Removing event listeners');
            window.removeEventListener('favoriteChanged', handleFavoriteChange);
            window.removeEventListener('userLoggedIn', handleUserLogin);
            window.removeEventListener('categoriesUpdated', handleCategoriesUpdate);
        };
    }, [handleSync]);

    // 移除阻塞初始化的 useEffect
    useEffect(() => {
        const initialize = async () => {
            try {
                console.log('🚀 Starting initialization...');
                
                // 首次加载时的同步，但不阻塞渲染
                const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
                if (isAuthenticated && lastSyncTime === 0) {
                    // 使用 setTimeout 延迟执行同步，不阻塞渲染
                    setTimeout(() => {
                        handleSync(true);
                    }, 0);
                }
                
                // 只在组件首次加载时获取收藏，也不阻塞渲染
                if (favorites.length === 0) {
                    setTimeout(async () => {
                        const storedFavorites = await getFavoritesFromIndexedDB();
                        setFavorites(storedFavorites);
                        setFilteredFavorites(storedFavorites);
                    }, 0);
                }
            } catch (error) {
                console.error('❌ Error during initialization:', error);
            }
        };
    
        initialize();
    }, [apiUrl, lastSyncTime, handleSync, favorites.length]);

    // 根据分类过滤收藏内容
    const filterFavoritesByCategory = (category) => {
        if (!category) {
            setFilteredFavorites(favorites); // Show all favorites when no category is selected
        } else {
            const filtered = favorites.filter(fav => fav.category === category);
            setFilteredFavorites(filtered);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (linkLoading) {
            handleApiResponse.warning('Please wait for the current request to complete');
            return;
        }
        
        setLinkLoading(true);
        
        try {
            const savedFavorite = await processLink(
                url, 
                apiInstance, 
                setProcessingSteps,
                {
                    setCategory,
                    setSummary,
                    setKeyPoints,
                    setEmbedding,
                    setFavorites,
                    setFilteredFavorites,
                    setExpandedItemUrl,
                    favorites,
                    apiUrl,
                    csrfToken: Cookies.get('csrftoken')
                }
            );

            if (savedFavorite) {
                handleApiResponse.success();

                setTimeout(() => {
                    setFavorites(prevFavorites => 
                        prevFavorites.map(fav => 
                            fav.url === url ? { ...fav, isNew: false } : fav
                        )
                    );
                }, 30000);
            }

        } catch (error) {
            if (error.response) {
                switch (error.response.status) {
                    case 429:
                        handleApiResponse.error('Server is busy. Please try again later.');
                        break;
                    case 404:
                        handleApiResponse.error('Unable to access the webpage. Please check if the URL is correct.');
                        break;
                    case 500:
                        handleApiResponse.error('Server error. Please try again later.');
                        break;
                    default:
                        handleApiResponse.error(error);
                }
            } else if (error.code === 'ECONNABORTED') {
                handleApiResponse.error('Request timed out. Please check if the URL is accessible.');
            } else {
                handleApiResponse.error(error);
            }
        } finally {
            setTimeout(() => {
                setProcessingSteps([]);
                setLinkLoading(false);
            }, 1500);
        }
    };

    // Layout component to avoid repetition
    const PageLayout = ({ children, title }) => (
        <>
        <Header title={title} />
        {children}
        <Footer />
        </>
    );

    console.log('Current API URL:', apiUrl);

    return (
        <Routes>
            <Route path={AUTH_ROUTES.LOGIN} element={<LoginForm />} />
            <Route path={AUTH_ROUTES.REGISTER} element={<RegisterForm />} />
            <Route path={AUTH_ROUTES.VERIFY_EMAIL} element={<VerifyEmail />} />
            <Route path={AUTH_ROUTES.EMAIL_VERIFIED_SUCCESS} element={<EmailVerifiedSuccess />} />
            <Route path={AUTH_ROUTES.EMAIL_VERIFIED_ERROR} element={<EmailVerifiedError />} />
            <Route 
                path={AUTH_ROUTES.CONFIRM_EMAIL} 
                element={<EmailConfirmation />} 
            />
            <Route
                path={AUTH_ROUTES.ACCOUNT_SETTINGS} 
                element={
                    <ProtectedRoute>
                        <PageLayout title="Account Settings">
                            <AccountSettings />
                        </PageLayout>
                    </ProtectedRoute>
                }
            />
            <Route 
                path="/" 
                element={
                    <LandingPage />
                } 
            />
            <Route 
                path="/app" 
                element={
                    <AuthRoute>
                        {({ user }) => {
                            console.log('🔑 AuthRoute /app:', { user });
                            return (
                                <ErrorBoundary>
                                    <Header title="LinkSort.ai" />
                                    <MainLayout 
                                        url={url} 
                                        setUrl={setUrl} 
                                        handleSubmit={handleSubmit} 
                                        loading={linkLoading}
                                        processingSteps={processingSteps}
                                        favorites={filteredFavorites}
                                        setFavorites={setFavorites}    
                                        setFilteredFavorites={setFilteredFavorites}
                                        categories={categories} 
                                        setCategories={setCategories}
                                        filterFavoritesByCategory={filterFavoritesByCategory}
                                        expandedItemUrl={expandedItemUrl}
                                        setExpandedItemUrl={setExpandedItemUrl}
                                    />
                                    <Footer />
                                </ErrorBoundary>
                            );
                        }}
                    </AuthRoute>
                } 
            />

            <Route path="/features" element={
                <>
                    <Header title="Features" />
                    <Features />
                    <Footer />
                </>
            } />
            <Route path="/help" element={
                <>
                    <Header title="Help Center" />
                    <HelpCenter />
                    <Footer />
                </>
            } />
            <Route path="/contact" element={
                <>
                    <Header title="Contact Us" />
                    <Contact />
                    <Footer />
                </>
            } />
            
            <Route path="/terms-of-service" element={
                <>
                    <Header title="LinkSort.ai" />
                    <TermsOfService />
                    <Footer />
                </>
            } />
            <Route path="/privacy-policy" element={
                <>
                    <Header title="LinkSort.ai" />
                    <PrivacyPolicy />
                    <Footer />
                </>
            } />
            <Route path="/refund-policy" element={
                <>
                    <Header title="LinkSort.ai" />
                    <RefundPolicy />
                    <Footer />
                </>
            } />
            <Route path="/chat/:url" element={
                <>
                    <Header title="LinkSort.ai" />
                    <ChatPage />
                    <Footer />
                </>
            } />
            <Route path="/pricing" element={
                <>
                    <Header title="Pricing" />
                    <PricingPage />
                    <Footer />
                </>
            } />
            <Route path="/payment/success" element={
                <ProtectedRoute>
                    <PageLayout title="Payment Successful">
                        <PaymentSuccess />
                    </PageLayout>
                </ProtectedRoute>
            } />
            <Route path="/payment/error" element={
                <ProtectedRoute>
                    <PageLayout title="Payment Failed">
                        <PaymentError />
                    </PageLayout>
                </ProtectedRoute>
            } />
            <Route 
                path="/billing"
                element={
                    <ProtectedRoute>
                        <PageLayout title="Billing">
                            <BillingRedirect />
                        </PageLayout>
                    </ProtectedRoute>
                }
            />

            <Route 
                path={AUTH_ROUTES.PASSWORD_CHANGE} 
                element={
                    <ProtectedRoute>
                        <PageLayout title="Change Password">
                            <PasswordChange />
                        </PageLayout>
                    </ProtectedRoute>
                }
            />

            {/* 404 Route */}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    );
};

export default App;
