// components/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Footer.css';

function Footer() {
  return (
    <footer className="App-footer">
      <p>
        <Link to="/terms-of-service">Terms of Service</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/refund-policy">Refund Policy</Link>
      </p>
    </footer>
  );
}

export default Footer;
