// components/LandingPage.js
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  BookOpen,
  Newspaper,
  Share2,
  Check,
  LogIn,
  UserPlus,
  Rocket
} from "lucide-react";
import '../css/LandingPage.css';
import { useAuth } from './auth/AuthContext';

const handleRandomExample = (exampleUrls, setUrl, formRef) => {
  const randomExample = exampleUrls[Math.floor(Math.random() * exampleUrls.length)];
  setUrl(randomExample.url);
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      if (formRef.current) {
        const submitBtn = formRef.current.querySelector('.demo-form button[type="submit"]');
        if (submitBtn) {
          submitBtn.click();
        } else {
          console.error('Submit button not found in form');
        }
      } else {
        console.error('Form reference not found');
      }
    });
  });
};

const Navigation = ({ navigate, formRef, setUrl, exampleUrls, isAuthReady, renderAuthButton }) => (
  <nav className="nav-container">
    <div className="logo-section">
      <Link to="/" className="logo-link">
        <img 
          src="/linksort_star.svg" 
          alt="LinkSort Logo" 
          className="logo-icon"
        />
        <span className="logo-text">LinkSort.ai</span>
      </Link>
    </div>
    <div className="nav-buttons">
      {renderAuthButton()}
    </div>
  </nav>
);

const HeroSection = () => (
  <div className="hero-section">
    <h1 className="hero-title">LinkSort.ai - AI-Powered Link Management & Summarization Tool</h1>
    <div className="hero-description">
      <p className="hero-description-line">The best place to organize all your links, with AI agents.</p>
      <p className="hero-description-line">Organize, summarize, save, search, and discover links effortlessly.</p>
    </div>
  </div>
);

const DemoSection = React.memo(({ url, setUrl, isLoading, error, handleUrlSubmit, exampleUrls, formRef, isAuthReady }) => {
  console.log('[DemoSection] Rendering with:', { url, isLoading, error });
  
  const [localError, setLocalError] = useState('');
  
  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setLocalError('');

    if (!url.trim()) {
      handleRandomExample(exampleUrls, setUrl, formRef);
      return;
    }

    if (!url.match(/^https?:\/\/.+/)) {
      setLocalError('Please enter a valid URL starting with http:// or https://');
      return;
    }
    
    console.log('[DemoSection] Submitting URL:', url);
    handleUrlSubmit(e);
  }, [url, handleUrlSubmit, setUrl, exampleUrls, formRef]);

  const onChange = useCallback((e) => {
    console.log('[DemoSection] URL input changed:', e.target.value);
    setUrl(e.target.value);
  }, [setUrl]);

  const handleExampleClick = useCallback((exampleUrl) => {
    setUrl(exampleUrl);
    requestAnimationFrame(() => {
      if (formRef.current) {
        const submitBtn = formRef.current.querySelector('button[type="submit"]');
        if (submitBtn) {
          submitBtn.click();
        }
      }
    });
  }, [setUrl, formRef]);

  return (
    <div className="demo-section">
      <form ref={formRef} onSubmit={onSubmit} className="demo-form">
        <input
          type="url"
          placeholder="Enter any webpage URL..."
          value={url}
          onChange={onChange}
          className="url-input"
          title="Please enter a valid URL starting with http:// or https://"
          disabled={isLoading}
        />
        <button 
          type="submit" 
          disabled={isLoading} 
          className={`submit-btn ${!url.trim() ? 'empty-url' : ''} ${isLoading ? 'loading' : ''}`}
          title={url.trim() ? 'Click to analyze this URL' : 'Click to try a random example'}
        >
          {isLoading ? 'Processing...' : 'Start Free Trial'}
        </button>
      </form>
      {(localError || error) && <p className="error-message">{localError || error}</p>}
      {isLoading && (
        <p className="text-sm text-gray-600 mt-2 text-center">
          Redirecting to summary page...
        </p>
      )}
      
      <div className="example-urls-list" aria-hidden="true">
        {exampleUrls.map((example, index) => (
          <button
            key={index}
            onClick={() => handleExampleClick(example.url)}
            className="example-url-button"
            disabled={isLoading}
          >
            <span className="example-url-icon">{example.icon}</span>
            <span className="example-url-label">{example.label}</span>
          </button>
        ))}
      </div>

      <div className="mt-4" aria-hidden="true">
        <Link
          to="/register"
          className="text-blue-600 hover:text-blue-700 font-medium"
        />
      </div>
    </div>
  );
});

const FEATURES = [
  {
    icon: "🚀",
    title: "Instant Summaries",
    description: "Get AI-powered summaries of any article in seconds"
  },
  {
    icon: "💬",
    title: "Interactive Chat",
    description: "Chat with AI about any saved article to get deeper insights, ask questions, and explore related topics"
  },
  {
    icon: "📚",
    title: "Auto-Organization",
    description: "Let AI categorize and tag your content automatically"
  },
  {
    icon: "🔍",
    title: "Easy Search",
    description: "Quickly find any saved content by searching through URLs, summaries, categories, and key points"
  },
  {
    icon: "📰",
    title: "Smart Recommendations",
    description: "Discover personalized news based on your interests"
  },
  {
    icon: "🔄",
    title: "Cross-Platform Sync",
    description: "Access your content seamlessly across all devices"
  }
];

const FeaturesSection = () => (
  <div className="features-section" role="region" aria-labelledby="features-title">
    <h2 id="features-title" className="section-title">Key Features</h2>
    <div className="highlights-grid">
      {FEATURES.map((feature, index) => (
        <div key={index} className="highlight-item">
          <div className="highlight-icon">{feature.icon}</div>
          <h4 className="highlight-title">{feature.title}</h4>
          <p className="highlight-text">{feature.description}</p>
        </div>
      ))}
    </div>
  </div>
);

const ExamplesSection = () => {
  return (
    <div className="examples-section">
      <h2 className="section-title">See It In Action</h2>
      <div style={{
        position: 'relative',
        boxSizing: 'content-box',
        maxHeight: '80svh',
        width: '100%',
        aspectRatio: '1.9699042407660738',
        padding: '40px 0 40px 0'
      }}>
        <iframe 
          src="https://app.supademo.com/embed/cm3s3j8hw10we3vuksbtr1tid?embed_v=2"
          loading="lazy"
          title="Linksort Demo"
          allow="clipboard-write"
          sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        />
      </div>
    </div>
  );
};

const FAQSection = () => {
  const faqs = [
    {
      question: "What is LinkSort.ai?",
      answer: "LinkSort.ai is an AI-powered link management tool that helps you organize, summarize, and discover web content. It uses advanced AI to automatically generate summaries of articles and find related content."
    },
    {
      question: "How does the AI summarization and categorization work?",
      answer: "Our AI automatically analyzes and categorizes your content, organizing links into relevant topics and collections. It generates concise summaries highlighting key points, making it easy to review and find information later. The system learns from your preferences to improve categorization over time."
    },
    {
      question: "Can I access my links across different devices?",
      answer: "Yes! LinkSort.ai offers seamless cross-device synchronization. Your links, summaries, and collections are instantly available on all your devices - desktop, mobile, or tablet. Changes made on one device automatically sync across all others."
    },
    {
      question: "How does the news recommendation system work?",
      answer: "Our AI analyzes your saved content to understand your interests and automatically recommends relevant news articles and similar content. You'll discover new perspectives and stay updated on topics that matter to you, all personalized based on your reading patterns."
    },
    {
      question: "Is there a free plan available?",
      answer: "Yes! We offer a free plan that includes core features like AI summarization, basic link management, and content recommendations. You can try LinkSort.ai without any commitment or credit card required."
    },
    {
      question: "How secure is my data?",
      answer: "We take security seriously. All data is encrypted in transit and at rest, and we never share your personal information with third parties. You have full control over your saved links, summaries, and preferences."
    }
  ];

  return (
    <div className="faq-section">
      <h2 className="section-title">Frequently Asked Questions</h2>
      <div className="faq-grid">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <h3 className="faq-question">{faq.question}</h3>
            <p className="faq-answer">{faq.answer}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const SignupSection = ({ email, setEmail, error, isSubscribing, handleEmailSubmit, navigate, formRef, setUrl, exampleUrls, isAuthReady }) => (
  <section className="signup-section">
    <div className="signup-content">
      <h2 className="section-title">Start Using LinkSort.ai Today</h2>
      <div className="value-propositions">
        <h3 className="value-title">Transform Your Web Reading Experience</h3>
      </div>
      
      <div className="signup-container">
        <div className="buttons-wrapper">
          <button
            onClick={() => handleRandomExample(exampleUrls, setUrl, formRef)}
            className="signup-btn primary"
            aria-label="Start free trial of LinkSort.ai"
          >
            <div className="btn-content">
              <Rocket className="btn-icon" aria-hidden="true" />
              <span className="btn-text">Start Free Trial</span>
            </div>
          </button>

          <span className="text-sm text-gray-500" aria-hidden="true">or</span>

          <button
            onClick={() => navigate('/login')}
            className="signup-btn secondary"
            aria-label="Sign in to your LinkSort.ai account"
          >
            <div className="btn-content">
              <LogIn className="btn-icon" aria-hidden="true" />
              <span className="btn-text">Sign In to Your Account</span>
            </div>
          </button>
        </div>

        <div className="benefits-grid" role="list">
          <div className="benefit-item" role="listitem">
            <div className="benefit-content">
              <Check className="check-icon" aria-hidden="true" />
              <span className="benefit-text">Free plan with essential features</span>
            </div>
          </div>
          <div className="benefit-item" role="listitem">
            <div className="benefit-content">
              <Check className="check-icon" aria-hidden="true" />
              <span className="benefit-text">No credit card required</span>
            </div>
          </div>
          <div className="benefit-item" role="listitem">
            <div className="benefit-content">
              <Check className="check-icon" aria-hidden="true" />
              <span className="benefit-text">Unlimited article summaries</span>
            </div>
          </div>
        </div>

        <div className="pro-plans-link">
          Ready to unlock more features?{' '}
          <Link 
            to="/pricing" 
            className="text-blue-600 hover:text-blue-700 font-medium"
            aria-label="View LinkSort.ai pro plans and pricing"
          >
            Explore Pro Plans
          </Link>
        </div>
      </div>
    </div>
  </section>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/features" className="footer-link">Features</Link>
          <span className="footer-separator">·</span>
          <Link to="/help" className="footer-link">Help Center</Link>
          <span className="footer-separator">·</span>
          <Link to="/contact" className="footer-link">Contact</Link>
          <span className="footer-separator">·</span>
          <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
          <span className="footer-separator">·</span>
          <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
          <span className="footer-separator">·</span>
          <Link to="/refund-policy" className="footer-link">Refund Policy</Link>
        </div>
        <div className="footer-copyright">
          © {currentYear} LinkSort.ai by DIGTECH AI LTD. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const isSubmitting = useRef(false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const { user, loading, isInitialized } = useAuth();
  
  const [state, setState] = useState({
    url: '',
    email: '',
    isLoading: false,
    isSubscribing: false,
    error: ''
  });

  const { url, email, isLoading, isSubscribing, error } = state;

  // 监听认证状态变化，但不阻塞渲染
  useEffect(() => {
    if (isInitialized) {
      setIsAuthReady(true);
    }
  }, [isInitialized]);

  // URL 验证函数
  const validateUrl = useCallback((inputUrl) => {
    console.log('[Landing] Validating URL:', inputUrl);
    if (!inputUrl.trim()) {
      return 'Please enter a URL';
    }
    try {
      new URL(inputUrl);
      return null;
    } catch {
      return 'Please enter a valid URL';
    }
  }, []);

  // 移除 initialized 和 mountedRef，简化初始化逻辑
  useEffect(() => {
    const pendingUrl = sessionStorage.getItem('pendingUrl');
    if (pendingUrl) {
      setState(prev => ({ ...prev, url: pendingUrl }));
      sessionStorage.removeItem('pendingUrl');
    }
  }, []);

  const handleUrlSubmit = useCallback(async (e) => {
    e.preventDefault();
    console.log('[Landing] URL submission triggered:', { url, isSubmitting: isSubmitting.current });

    if (isSubmitting.current) {
      console.log('[Landing] Submission blocked - already submitting');
      return;
    }

    // 如果 URL 为空，不进行验证，因为我们会在 onSubmit 中处理
    if (!url.trim()) {
      return;
    }

    const urlError = validateUrl(url);
    if (urlError) {
      console.log('[Landing] URL validation failed:', urlError);
      setState(prev => ({ ...prev, error: urlError }));
      return;
    }

    try {
      isSubmitting.current = true;
      setState(prev => ({ ...prev, isLoading: true, error: '' }));
      console.log('[Landing] Storing URL and navigating:', url);
      
      sessionStorage.setItem('pendingUrl', url);
      setTimeout(() => {
        navigate('/app');
      }, 100);
      
    } catch (err) {
      console.error('[Landing] Submission error:', err);
      setState(prev => ({
        ...prev,
        error: err.message || 'An error occurred',
        isLoading: false
      }));
      sessionStorage.removeItem('pendingUrl');
    } finally {
      isSubmitting.current = false;
    }
  }, [url, navigate, validateUrl]);

  // 状态更新处理器
  const setUrl = useCallback((newUrl) => {
    setState(prev => ({ ...prev, url: newUrl }));
  }, []);

  const setEmail = useCallback((newEmail) => {
    setState(prev => ({ ...prev, email: newEmail }));
  }, []);

  // Email 处理
  const handleEmailSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!email.includes('@')) {
      setState(prev => ({ ...prev, error: 'Please enter a valid email address' }));
      return;
    }

    setState(prev => ({ ...prev, isSubscribing: true, error: '' }));
    
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setState(prev => ({ ...prev, email: '', isSubscribing: false }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: 'Failed to join beta. Please try again.',
        isSubscribing: false
      }));
    }
  }, [email]);

  // 记录状态变化
  useEffect(() => {
    console.log('[Landing] State updated:', { url, isLoading, error });
  }, [url, isLoading, error]);

  const formRef = useRef(null);
  const exampleUrls = useMemo(() => [
    {
      url: 'https://www.sciencedaily.com/releases/2025/01/250117171309.htm',
      label: 'Science Article',
      icon: '🧬'
    },
    {
      url: 'https://www.economist.com/leaders/2024/11/14/whats-about-to-hit-the-world-economy',
      label: 'Economics Article',
      icon: '📈'
    },
    {
      url: 'https://techcrunch.com/2024/11/22/chatgpt-everything-to-know-about-the-ai-chatbot/',
      label: 'Tech News',
      icon: '💻'
    }
  ], []);

  // 修改认证按钮渲染逻辑
  const renderAuthButton = () => {
    // 移除 isAuthReady 检查，直接返回按钮
    return (
      <>
        <button 
          className="nav-btn outline" 
          onClick={() => navigate('/login')}
        >
          <div className="btn-content">
            <LogIn className="btn-icon" />
            <span className="btn-text">Sign In</span>
          </div>
        </button>
        <button 
          className="nav-btn primary" 
          onClick={() => handleRandomExample(exampleUrls, setUrl, formRef)}
        >
          <div className="btn-content">
            <Rocket className="btn-icon" />
            <span className="btn-text">Start Free Trial</span>
          </div>
        </button>
      </>
    );
  };

  return (
    <div className="landing-page">
      <Navigation 
        navigate={navigate} 
        formRef={formRef} 
        setUrl={setUrl} 
        exampleUrls={exampleUrls}
        isAuthReady={isAuthReady}
        renderAuthButton={renderAuthButton}
      />
      <main className="main-content">
        <HeroSection />
        <DemoSection
          url={url}
          setUrl={setUrl}
          isLoading={isLoading}
          error={error}
          handleUrlSubmit={handleUrlSubmit}
          exampleUrls={exampleUrls}
          formRef={formRef}
          isAuthReady={isAuthReady}
        />
        <FeaturesSection />
        <ExamplesSection />
        <FAQSection />
        <SignupSection
          email={email}
          setEmail={setEmail}
          error={error}
          isSubscribing={isSubscribing}
          handleEmailSubmit={handleEmailSubmit}
          navigate={navigate}
          formRef={formRef}
          setUrl={setUrl}
          exampleUrls={exampleUrls}
          isAuthReady={isAuthReady}
        />
      </main>
      <Footer />
    </div>
  );
};

export default Landing;