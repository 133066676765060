import React from "react";
import LegalLayout from "./LegalLayout";

const Section = ({ title, children }) => (
  <div className="mb-8 text-center">
    <h2 className="text-2xl font-semibold text-gray-800 mb-4">{title}</h2>
    <div className="text-left">
      {children}
    </div>
  </div>
);

const List = ({ children }) => (
  <ul className="list-disc pl-6 mb-6 space-y-2 text-gray-600 max-w-2xl mx-auto text-left">
    {children}
  </ul>
);

const RefundPolicy = () => {
  return (
    <LegalLayout title="Refund Policy">
      <p className="text-gray-600 mb-8 text-lg max-w-2xl mx-auto">
        At linksort.ai, we want you to be completely satisfied with our service. 
        Here's our refund policy:
      </p>

      <Section title="Refund Eligibility">
        <List>
          <li>7-day money-back guarantee for new subscriptions</li>
          <li>Full refund if you're not satisfied with the service</li>
          <li>Refunds for technical issues preventing service usage</li>
          <li>Pro-rated refunds may be considered in exceptional cases</li>
        </List>
      </Section>

      <Section title="How to Request a Refund">
        <List>
          <li>Email support@linksort.ai with your request</li>
          <li>Include your account email and reason for refund</li>
          <li>We'll process valid requests within 5-7 business days</li>
          <li>Refunds are issued to the original payment method</li>
        </List>
      </Section>

      <Section title="Contact Us">
        <p className="text-gray-600 max-w-2xl mx-auto">
          If you have any questions about our refund policy, please contact us at{" "}
          <a 
            href="mailto:support@linksort.ai"
            className="text-blue-600 hover:text-blue-800 underline"
          >
            support@linksort.ai
          </a>
        </p>
      </Section>
    </LegalLayout>
  );
};

export default RefundPolicy;
