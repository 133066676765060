// components/Contact.js
import React from "react";
import { Mail } from "lucide-react";
import "../css/Contact.css";
import TawkMessenger from './utils/TawkMessenger';

const Contact = () => {
  const contactMethods = [
    {
      icon: <Mail className="w-6 h-6" />,
      title: "Email",
      description: "support@linksort.ai",
      link: "mailto:support@linksort.ai"
    }
  ];

  return (
    <div className="contact-container">
      <TawkMessenger />
      <div className="contact-header">
        <h1 className="text-3xl font-bold mb-2">Contact Us</h1>
        <p className="text-gray-600">We're here to help and answer any questions you might have</p>
      </div>

      <div className="contact-methods">
        {contactMethods.map((method, index) => (
          <ContactMethod key={index} {...method} />
        ))}
      </div>
    </div>
  );
};

const ContactMethod = ({ icon, title, description, link }) => {
  return (
    <a 
      href={link}
      className="contact-method-card"
    >
      <div className="text-blue-600 bg-blue-50 p-4 rounded-full mb-4">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </a>
  );
};

export default Contact;