// src/components/LinkProcessor.js

import { handleApiResponse } from './Toast';
import { createProcessingStep } from './ProcessingSteps';
import { PROCESSING_STEPS } from './ProcessingSteps';
import { handleCompareWithCategories } from './categoryUtils';
import { generateCategoryEmbedding } from './categoryUtils';
import { generateQuery } from './generateQuery';
import { 
    saveFavoritesToIndexedDB, 
    saveEmbeddingToIndexedDB, 
    saveCategoryEmbeddingToIndexedDB,
    getCategoriesFromIndexedDB,
    getCategoryEmbeddingFromIndexedDB 
} from './indexeddb';

export const processLink = async (
    url, 
    apiInstance, 
    setProcessingSteps,
    {
        setCategory,
        setSummary,
        setKeyPoints,
        setEmbedding,
        setFavorites,
        setFilteredFavorites,
        setExpandedItemUrl,
        favorites,
        apiUrl,
        csrfToken
    }
) => {
    if (!url || url.trim() === '') {
        handleApiResponse.emptyUrl();
        return null;
    }

    try {
        new URL(url.trim());
    } catch (e) {
        handleApiResponse.invalidUrl();
        return null;
    }

    const payload = { url: url.trim() };

    // Analyze article content
    await createProcessingStep(setProcessingSteps, PROCESSING_STEPS.ANALYZING, async () => {
        await new Promise(resolve => setTimeout(resolve, 1000));
    });

    // Process content and generate summary
    const processResponse = await createProcessingStep(
        setProcessingSteps, 
        PROCESSING_STEPS.GENERATING, 
        async () => {
            const maxRetries = 1;
            let retryCount = 0;
            let lastError = null;

            while (retryCount < maxRetries) {
                try {
                    const response = await apiInstance.post('/sort/process/', payload, {
                        headers: { 'Content-Type': 'application/json' },
                        timeout: 30000
                    });
                    return response;
                } catch (error) {
                    lastError = error;
                    
                    if (error.response && error.response.status === 429) {
                        handleApiResponse.warning('Server is busy. Please try again later.');
                        throw error;
                    }
                    
                    if (error.code === 'ECONNABORTED') {
                        handleApiResponse.warning('Request timed out. Please check if the URL is accessible.');
                        throw error;
                    }

                    retryCount++;
                    if (retryCount < maxRetries) {
                        await new Promise(resolve => setTimeout(resolve, 2000));
                        handleApiResponse.warning(`Retrying (${retryCount}/${maxRetries})`);
                    }
                }
            }
            
            throw lastError;
        }
    );

    if (!processResponse) {
        return null;
    }

    const {
        url: responseUrl,
        title,
        summary: newSummary,
        category: finalCategory,
        category_from_gpt: categoryFromGPT,
        key_points: keyPoints,
        embedding: newEmbedding,
        main_image_url: mainImageUrl,
        created_at: createdAt,
        updated_at: updatedAt
    } = processResponse.data;

    // 更新状态
    setSummary(newSummary);
    setKeyPoints(keyPoints);
    setEmbedding(newEmbedding);
    setCategory(finalCategory);

    // 创建新的收藏对象
    const newFavorite = {
        url: responseUrl,
        title,
        summary: newSummary,
        keyPoints,
        category: finalCategory,
        categoryFromGPT,
        main_image_url: mainImageUrl,
        created_at: createdAt,
        updated_at: updatedAt,
        isNew: true
    };

    // 更新本地状态和 IndexedDB
    await createProcessingStep(
        setProcessingSteps,
        PROCESSING_STEPS.SAVING,
        async () => {
            // 创建新的收藏列表，处理可能的重复项
            const updatedFavorites = favorites.reduce((acc, fav) => {
                if (fav.url === newFavorite.url) {
                    // 如果URL已存在，跳过旧的版本
                    return acc;
                }
                return [...acc, fav];
            }, [newFavorite]); // 始终将新收藏放在开头

            setFavorites(updatedFavorites);

            setFilteredFavorites(prevFiltered => {
                // 使用相同的reduce逻辑处理过滤后的列表
                return prevFiltered.reduce((acc, fav) => {
                    if (fav.url === newFavorite.url) {
                        // 如果URL已存在，跳过旧的版本
                        return acc;
                    }
                    return [...acc, fav];
                }, [newFavorite]); // 始终将新收藏放在开头
            });

            // 保存到 IndexedDB，使用处理过的收藏列表
            await saveFavoritesToIndexedDB(updatedFavorites, false);
            setExpandedItemUrl(url);
        }
    );

    return newFavorite;
}; 