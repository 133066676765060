// components/Features.js
import React from "react";
import { 
  BookOpen, 
  Newspaper, 
  Share2, 
  Search, 
  Save, 
  Zap,
  Brain,
  MessageSquare,
  Tag,
  Clock,
  Globe,
  Shield
} from "lucide-react";
import { Link } from "react-router-dom";

const Features = () => {
  const mainFeatures = [
    {
      icon: <Brain className="w-8 h-8 text-blue-600" />,
      title: "AI-Powered Summaries",
      description: "Get instant, accurate summaries of any webpage using advanced AI. Save hours of reading time while capturing key insights."
    },
    {
      icon: <Tag className="w-8 h-8 text-blue-600" />,
      title: "Smart Categorization",
      description: "Our AI automatically organizes your links into relevant categories, making it easy to find and manage your content."
    },
    {
      icon: <MessageSquare className="w-8 h-8 text-blue-600" />,
      title: "Interactive Chat",
      description: "Chat with AI about any saved article to get deeper insights, ask questions, and explore related topics."
    },
    {
      icon: <Search className="w-8 h-8 text-blue-600" />,
      title: "Smart Search",
      description: "Quickly find any saved content by searching through URLs, summaries, categories, and key points - all in one place."
    },
    {
      icon: <Clock className="w-8 h-8 text-blue-600" />,
      title: "Related Content",
      description: "Track your saved content and discover related news articles based on your collection of bookmarks."
    },
    {
      icon: <Globe className="w-8 h-8 text-blue-600" />,
      title: "Cross-Platform Access",
      description: "Access your links and summaries from any device with seamless cloud synchronization."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-6xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Powerful Features for Smart Reading</h1>
          <p className="text-xl text-gray-600">Everything you need to organize, understand, and share web content</p>
        </div>
        
        {/* Main Features Grid */}
        <div className="grid md:grid-cols-3 gap-8 mb-16">
          {mainFeatures.map((feature, index) => (
            <FeatureCard
              key={index}
              icon={feature.icon}
              title={feature.title}
              description={feature.description}
            />
          ))}
        </div>

        {/* Security Section */}
        <div className="bg-white rounded-xl shadow-lg p-8 mb-16 max-w-4xl mx-auto">
          <div className="flex items-center justify-center mb-6">
            <Shield className="w-8 h-8 text-blue-600 mr-4" />
            <h2 className="text-2xl font-semibold">Enterprise-Grade Security</h2>
          </div>
          <p className="text-gray-600 mb-4 text-center">
            Your data is protected with industry-leading security measures:
          </p>
          <ul className="grid md:grid-cols-2 gap-4 text-gray-600 max-w-2xl mx-auto">
            <li className="flex items-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              End-to-end encryption
            </li>
            <li className="flex items-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              Regular security audits
            </li>
            <li className="flex items-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              GDPR compliance
            </li>
            <li className="flex items-center">
              <Check className="w-5 h-5 text-green-500 mr-2" />
              Data backup & recovery
            </li>
          </ul>
        </div>

        {/* CTA Section */}
        <div className="text-center max-w-2xl mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Ready to transform your reading experience?</h2>
          <p className="text-gray-600 mb-8">Start your free trial today - no credit card required.</p>
          <div className="flex justify-center gap-8">
            <Link 
              to="/register" 
              className="bg-blue-600 text-white px-12 py-3 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Start Free Trial
            </Link>
            {" "}
            <Link 
              to="/pricing" 
              className="border border-blue-600 text-blue-600 px-12 py-3 rounded-lg hover:bg-blue-50 transition-colors"
            >
              View Pricing
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow text-center">
      <div className="mb-4 flex justify-center">{icon}</div>
      <h3 className="text-xl font-semibold mb-2">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  );
};

const Check = ({ className }) => (
  <svg 
    className={className} 
    viewBox="0 0 24 24" 
    fill="none" 
    stroke="currentColor" 
    strokeWidth="3"
    strokeLinecap="round" 
    strokeLinejoin="round"
    width="16"
    height="16"
  >
    <polyline points="20 6 9 17 4 12" />
  </svg>
);

export default Features;