// src/components/auth/AccountSettings.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Mail, Lock, LogOut, AlertCircle, Loader2, RefreshCw, CreditCard, Crown } from 'lucide-react';
import { useAuth } from './AuthContext';
import '../../css/AccountSettings.css';
import { createApiInstance } from './axiosConfig';

// Section Component
const SettingsSection = ({ title, description, icon: Icon, children }) => (
  <div className="settings-section">
    <div className="settings-header">
      <div>
        <h3 className="settings-title">{title}</h3>
        <p className="settings-description">{description}</p>
      </div>
      <Icon className="settings-icon" />
    </div>
    <div className="settings-content">{children}</div>
  </div>
);

// Action Button Component
const ActionButton = ({ 
  onClick, 
  disabled, 
  icon: Icon, 
  loadingText, 
  text, 
  isLoading, 
  variant = "default" 
}) => (
  <button
    onClick={onClick}
    disabled={disabled || isLoading}
    className={`action-button ${variant}`}
  >
    {isLoading ? (
      <>
        <Loader2 className="button-icon spinning" />
        {loadingText}
      </>
    ) : (
      <>
        <Icon className="button-icon" />
        {text}
      </>
    )}
  </button>
);

// Add this helper function
const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export const AccountSettings = () => {
  const navigate = useNavigate();
  const { user, logout, resendVerification } = useAuth();
  const [error, setError] = React.useState('');
  const [isSigningOut, setIsSigningOut] = React.useState(false);
  const [isResendingVerification, setIsResendingVerification] = React.useState(false);
  const [verificationSent, setVerificationSent] = React.useState(false);
  const [isLoadingPortal, setIsLoadingPortal] = useState(false);

  const handleSignOut = async () => {
    try {
      setIsSigningOut(true);
      setError('');
      await logout();
      navigate('/');
    } catch (err) {
      setError('Failed to sign out. Please try again.');
      console.error('Logout error:', err);
    } finally {
      setIsSigningOut(false);
    }
  };

  const handleResendVerification = async () => {
    try {
      setIsResendingVerification(true);
      setError('');
      await resendVerification();
      setVerificationSent(true);
    } catch (err) {
      setError('Failed to resend verification email. Please try again.');
      console.error('Verification error:', err);
    } finally {
      setIsResendingVerification(false);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsLoadingPortal(true);
      const apiInstance = createApiInstance();
      const response = await apiInstance.post('/sort/create-portal-session/');
      
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Failed to create portal session:', error);
      alert('Unable to access billing portal. Please try again later.');
    } finally {
      setIsLoadingPortal(false);
    }
  };

  // Loading state is handled by AuthProvider
  if (!user) {
    return (
      <div className="loading-container">
        <Loader2 className="loading-spinner" />
      </div>
    );
  }

  return (
    <div className="account-settings-container">
      <div className="account-settings-wrapper">
        <div className="account-settings-header">
          <h2 className="main-title">Account Settings</h2>
          <p className="main-description">
            Manage your account preferences and settings
          </p>
        </div>

        {error && (
          <div className="error-message">
            <AlertCircle className="alert-icon" />
            <span>{error}</span>
          </div>
        )}

        {verificationSent && (
          <div className="success-message">
            <AlertCircle className="alert-icon" />
            <span>Verification email sent successfully!</span>
          </div>
        )}

        <div className="settings-container">
          <SettingsSection
            title="Email Address"
            description={
              <>
                {user.email}
                {user.is_verified ? (
                  <span className="verification-status verified">(Verified)</span>
                ) : (
                  <span className="verification-status not-verified">(Not Verified)</span>
                )}
              </>
            }
            icon={Mail}
          >
            {!user.is_verified && (
              <ActionButton
                onClick={handleResendVerification}
                disabled={isResendingVerification}
                icon={RefreshCw}
                text="Resend Verification Email"
                loadingText="Sending..."
                isLoading={isResendingVerification}
              />
            )}
          </SettingsSection>

          <SettingsSection
            title="Password"
            description="Update your password"
            icon={Lock}
          >
            <Link
              to="/accounts/password/change/"
              className="w-full inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
              Change Password
            </Link>
          </SettingsSection>

          <SettingsSection
            title="Subscription"
            description={user?.is_premium ? "Manage your Pro subscription" : "Upgrade to Pro for more features"}
            icon={Crown}
          >
            {user?.is_premium ? (
              <>
                <div className="subscription-details">
                  <p className="subscription-status">Pro Plan</p>
                  <p className="subscription-period">
                    Next billing date: {formatDate(user?.subscription_end)}
                  </p>
                </div>
                <ActionButton
                  onClick={handleManageSubscription}
                  disabled={isLoadingPortal}
                  icon={CreditCard}
                  text="Manage Subscription"
                  loadingText="Loading portal..."
                  isLoading={isLoadingPortal}
                />
              </>
            ) : (
              <ActionButton
                onClick={() => navigate('/pricing')}
                icon={Crown}
                text="Upgrade to Pro"
                variant="primary"
              />
            )}
          </SettingsSection>

          <SettingsSection
            title="Sign Out"
            description="Sign out of your account"
            icon={LogOut}
          >
            <ActionButton
              onClick={handleSignOut}
              disabled={isSigningOut}
              icon={LogOut}
              text="Sign Out"
              loadingText="Signing Out..."
              isLoading={isSigningOut}
              variant="danger"
            />
          </SettingsSection>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;