// comp/syncFavorites.js

import Cookies from 'js-cookie';
import { getFavoritesFromIndexedDB, saveFavoritesToIndexedDB } from './indexeddb';
import { createApiInstance } from './auth/axiosConfig';

// 时间格式转换的辅助函数
const formatDateTime = (dateTimeString) => {
    return new Date(dateTimeString).toISOString();
};

// 检查用户认证状态的辅助函数
const isAuthenticatedUser = () => {
    const sessionId = Cookies.get('sessionid');
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    return !!sessionId && isAuthenticated;
};

const formatServerFavorite = (serverFav) => ({
    url: serverFav.url,
    title: serverFav.title,
    summary: serverFav.summary,
    keyPoints: serverFav.key_points,
    category: serverFav.category,
    categoryFromGPT: serverFav.category_from_gpt,
    main_image_url: serverFav.main_image_url,
    created_at: formatDateTime(serverFav.created_at),
    updated_at: formatDateTime(serverFav.updated_at)
});

// 添加一个新的同步状态变量
let isSyncing = false;

// 主同步函数
export const syncFavorites = async () => {
    // 1. 如果离线或者已经在同步中，直接返回本地数据
    if (!navigator.onLine || isSyncing) {
        console.log('Using local data:', !navigator.onLine ? 'Offline' : 'Sync in progress');
        return await getFavoritesFromIndexedDB();
    }

    try {
        isSyncing = true;
        
        // 2. 获取服务器数据
        const apiInstance = createApiInstance();
        const response = await apiInstance.get('/sort/sync_favorites/');
        const serverFavorites = response.data.map(serverFav => formatServerFavorite(serverFav));
        
        // 3. 直接用服务器数据更新本地存储
        await saveFavoritesToIndexedDB(serverFavorites);
        
        // 4. 触发同步完成事件
        window.dispatchEvent(new CustomEvent('favoritesSync', {
            detail: { success: true }
        }));
        
        return serverFavorites;

    } catch (error) {
        console.error('Error syncing favorites:', error);
        if (error.response) {
            console.error('Error response:', error.response.data);
        }
        
        // 5. 触发同步失败事件
        window.dispatchEvent(new CustomEvent('favoritesSync', {
            detail: { success: false, error }
        }));
        
        // 6. 如果同步失败，返回本地数据
        return await getFavoritesFromIndexedDB();
        
    } finally {
        isSyncing = false;
    }
};

// 添加网络状态监听（可选）
let isOnline = navigator.onLine;

window.addEventListener('online', () => {
    isOnline = true;
    // 可以在这里添加自动重新同步的逻辑
});

window.addEventListener('offline', () => {
    isOnline = false;
}); 