// src/components/auth/axiosConfig.js

import axios from 'axios';
import Cookies from 'js-cookie';
import { AUTH_CONFIG } from './constants';

const MAX_RETRY_ATTEMPTS = 3;

// CSRF Token管理器
const CSRFManager = {
  token: null,
  lastFetchTime: 0,
  
  async getToken() {
    const now = Date.now();
    // 如果token存在且未过期(15分钟内)，直接返回
    if (this.token && now - this.lastFetchTime < 900000) {
      console.log('Using cached CSRF token');
      return this.token;
    }
    
    try {
      console.log('Fetching new CSRF token');
      const response = await axios.get('/sort/get_csrf/', {
        withCredentials: true,
        headers: {
          'Accept': 'application/json',
          'X-Requested-With': 'XMLHttpRequest'
        }
      });
      
      this.token = response.data.csrfToken;
      this.lastFetchTime = now;
      
      if (this.token) {
        Cookies.set(AUTH_CONFIG.cookies.csrf, this.token);
        axios.defaults.headers.common['X-CSRFToken'] = this.token;
      }
      
      return this.token;
    } catch (error) {
      console.error('Failed to fetch CSRF token:', error);
      throw error;
    }
  },

  clearToken() {
    this.token = null;
    this.lastFetchTime = 0;
    Cookies.remove(AUTH_CONFIG.cookies.csrf);
    delete axios.defaults.headers.common['X-CSRFToken'];
  }
};

// 辅助函数：检查用户认证状态
const isAuthenticatedUser = () => {
    // 检查session cookie
    const sessionId = Cookies.get('sessionid');
    // 检查本地存储的认证标记
    const isAuthenticated = localStorage.getItem('isAuthenticated') === 'true';
    return !!sessionId && isAuthenticated;
};

export const createApiInstance = (baseURL = process.env.REACT_APP_API_URL) => {
    if (!baseURL) {
        console.warn('API base URL not provided, using default');
        baseURL = '';
    }

    const instance = axios.create({
        baseURL,
        withCredentials: true,
        timeout: 60000,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Accept': 'application/json'
        }
    });

    // Request interceptor
    instance.interceptors.request.use(
        async (config) => {
            console.log('🔍 Request to:', config.url);
            
            let token = null;  // 声明 token 变量
            
            // 只有在需要 CSRF token 的请求上才获取 token
            // 对于 GET 请求，我们可以跳过 CSRF token
            if (config.method && config.method.toLowerCase() !== 'get') {
                token = await CSRFManager.getToken();  // 赋值给 token
                if (token) {
                    config.headers['X-CSRFToken'] = token;
                }
            }

            // 检查用户认证状态
            const authenticated = isAuthenticatedUser();
            
            // 只有在未认证状态下才添加browser_id
            if (!authenticated) {
                const browserId = Cookies.get('browser_id');
                if (browserId) {
                    config.headers['X-Browser-Id'] = browserId;
                    console.log('📍 Adding browser_id for anonymous user:', browserId);
                }
            } else {
                console.log('🔑 Request from authenticated user - skipping browser_id');
            }

            // 处理FormData
            if (config.data instanceof FormData) {
                delete config.headers['Content-Type'];
            }

            console.log('🚀 Request:', {
                url: config.url,
                method: config.method,
                headers: {
                    ...config.headers,
                    'X-CSRFToken': token ? '[HIDDEN]' : undefined,
                    'X-Browser-Id': config.headers['X-Browser-Id'] ? '[HIDDEN]' : undefined
                },
                authenticated: authenticated,
                data: config.data instanceof FormData ? '[FormData]' : config.data
            });

            return config;
        },
        (error) => {
            console.error('Request interceptor error:', error);
            return Promise.reject(error);
        }
    );

    // Response interceptor (保持不变)
    instance.interceptors.response.use(
        (response) => {
            console.log('✅ Response:', {
                url: response.config.url,
                status: response.status,
                data: response.data
            });
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            const status = error.response?.status;
            const errorMessage = error.response?.data?.message 
                || error.response?.data?.error 
                || error.response?.data?.detail 
                || error.message;

            console.error(`❌ API error:`, {
                url: originalRequest?.url,
                status: status,
                data: error.response?.data,
                message: errorMessage,
                retryCount: originalRequest?._retryCount || 0
            });

            // Handle CSRF errors
            if (status === 403 && originalRequest && 
                (!originalRequest._retryCount || originalRequest._retryCount < MAX_RETRY_ATTEMPTS) && 
                (errorMessage?.includes('CSRF') || error.response?.data?.error?.includes('CSRF'))) {
                
                originalRequest._retryCount = (originalRequest._retryCount || 0) + 1;
                
                try {
                    // 清除旧token并获取新token
                    CSRFManager.clearToken();
                    const newToken = await CSRFManager.getToken();
                    
                    if (newToken) {
                        originalRequest.headers['X-CSRFToken'] = newToken;
                        return instance(originalRequest);
                    }
                } catch (retryError) {
                    console.error('Failed to refresh CSRF token:', retryError);
                    return Promise.reject(retryError);
                }
            }

            // Handle authentication errors
            if (status === 401) {
                // 清除认证状态
                localStorage.removeItem('isAuthenticated');
                window.dispatchEvent(new CustomEvent('auth:unauthorized'));
            }

            return Promise.reject(error);
        }
    );

    return instance;
};

// 其他函数保持不变
export const apiRequest = async (config, instance = null) => {
    const apiInstance = instance || createApiInstance();
    
    try {
        const response = await apiInstance(config);
        return response;
    } catch (error) {
        if (error.response?.data?.message) {
            throw new Error(error.response.data.message);
        }
        throw error;
    }
};

export const setupCSRF = async () => {
    return CSRFManager.getToken();
};

export const createRequestConfig = (method, url, data = null, additionalConfig = {}) => {
    const config = {
        method,
        url,
        ...additionalConfig
    };

    if (data) {
        config.data = data;
    }

    return config;
};

export default createApiInstance;