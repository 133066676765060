// src/legal/LegalLayout.js

import React from "react";

const LegalLayout = ({ title, children }) => {
  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-sm p-8">
        <h1 className="text-3xl font-bold text-gray-900 mb-8 pb-4 border-b text-center">
          {title}
        </h1>
        <div className="mx-auto">
          {children}
        </div>
      </div>
    </div>
  );
};

export default LegalLayout; 