// components/Toast.js
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// 创建 useToast hook
export const useToast = () => {
    return {
        showToast: ({ type, message, title, duration = 3000 }) => {
            switch (type) {
                case 'success':
                    toast.success(message, { autoClose: duration });
                    break;
                case 'error':
                    toast.error(message, { autoClose: duration });
                    break;
                case 'warning':
                    toast.warning(message, { autoClose: duration });
                    break;
                case 'info':
                    toast.info(message, { autoClose: duration });
                    break;
                default:
                    toast(message, { autoClose: duration });
            }
        }
    };
};

export const ToastProvider = () => {
    return (
        <ToastContainer 
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            closeOnClick
            pauseOnHover
        />
    );
};

export const showToast = {
    success: (message) => toast.success(message),
    error: (message) => toast.error(message),
    warning: (message) => toast.warning(message),
    info: (message) => toast.info(message)
};

export const handleApiResponse = {
    success: (message = 'Article successfully summarized and added to favorites!') => {
        showToast.success(message);
    },
    error: (error) => {
        const errorMessage = error.response?.data?.message || 
                           'Failed to process the article. Please try again later.';
        showToast.error(errorMessage);
    },
    invalidUrl: () => {
        showToast.error('Invalid URL format. Please enter a valid URL');
    },
    emptyUrl: () => {
        showToast.error('Please enter a valid URL');
    }
}; 