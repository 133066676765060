// components/LandingPage.js
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  BookOpen,
  Newspaper,
  Share2,
  Check,
  LogIn,
  UserPlus,
  Rocket
} from "lucide-react";
import '../css/LandingPage.css';
import { useAuth } from './auth/AuthContext';

const handleRandomExample = (exampleUrls, setUrl, formRef) => {
  const randomExample = exampleUrls[Math.floor(Math.random() * exampleUrls.length)];
  setUrl(randomExample.url);
  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      if (formRef.current) {
        const submitBtn = formRef.current.querySelector('.demo-form button[type="submit"]');
        if (submitBtn) {
          submitBtn.click();
        } else {
          console.error('Submit button not found in form');
        }
      } else {
        console.error('Form reference not found');
      }
    });
  });
};

const Navigation = ({ navigate, formRef, setUrl, exampleUrls, isAuthReady, renderAuthButton }) => (
  <nav className="nav-container">
    <div className="logo-section">
      <Link to="/" className="logo-link">
        <img 
          src="/linksort_star.svg" 
          alt="LinkSort Logo" 
          className="logo-icon"
        />
        <span className="logo-text">LinkSort.ai</span>
      </Link>
    </div>
    <div className="nav-buttons">
      {renderAuthButton()}
    </div>
  </nav>
);

const HeroSection = () => (
  <div className="hero-section">
    <h1 className="hero-title">The AI Link Manager</h1>
    <div className="hero-description">
      <p className="hero-description-line">The best place to organize all your links, with AI agents.</p>
      <p className="hero-description-line">Organize, summarize, save, search, and discover links effortlessly.</p>
    </div>
  </div>
);

const DemoSection = React.memo(({ url, setUrl, isLoading, error, handleUrlSubmit, exampleUrls, formRef, isAuthReady }) => {
  console.log('[DemoSection] Rendering with:', { url, isLoading, error });
  
  const [localError, setLocalError] = useState('');
  
  const onSubmit = useCallback((e) => {
    e.preventDefault();
    setLocalError('');

    if (!url.trim()) {
      handleRandomExample(exampleUrls, setUrl, formRef);
      return;
    }

    if (!url.match(/^https?:\/\/.+/)) {
      setLocalError('Please enter a valid URL starting with http:// or https://');
      return;
    }
    
    console.log('[DemoSection] Submitting URL:', url);
    handleUrlSubmit(e);
  }, [url, handleUrlSubmit, setUrl, exampleUrls, formRef]);

  const onChange = useCallback((e) => {
    console.log('[DemoSection] URL input changed:', e.target.value);
    setUrl(e.target.value);
  }, [setUrl]);

  const handleExampleClick = useCallback((exampleUrl) => {
    setUrl(exampleUrl);
    requestAnimationFrame(() => {
      if (formRef.current) {
        const submitBtn = formRef.current.querySelector('button[type="submit"]');
        if (submitBtn) {
          submitBtn.click();
        }
      }
    });
  }, [setUrl, formRef]);

  return (
    <div className="demo-section">
      <form ref={formRef} onSubmit={onSubmit} className="demo-form">
        <input
          type="url"
          placeholder="Enter any webpage URL..."
          value={url}
          onChange={onChange}
          className="url-input"
          title="Please enter a valid URL starting with http:// or https://"
          disabled={isLoading}
        />
        <button 
          type="submit" 
          disabled={isLoading} 
          className={`submit-btn ${!url.trim() ? 'empty-url' : ''} ${isLoading ? 'loading' : ''}`}
          title={url.trim() ? 'Click to analyze this URL' : 'Click to try a random example'}
        >
          {isLoading ? 'Processing...' : 'Start Free Trial'}
        </button>
      </form>
      {(localError || error) && <p className="error-message">{localError || error}</p>}
      {isLoading && (
        <p className="text-sm text-gray-600 mt-2 text-center">
          Redirecting to summary page...
        </p>
      )}
      
      <div className="example-urls-section">
        <p className="example-urls-title">Or try these examples:</p>
        <div className="example-urls-list">
          {exampleUrls.map((example, index) => (
            <button
              key={index}
              onClick={() => handleExampleClick(example.url)}
              className="example-url-button"
              disabled={isLoading}
            >
              <span className="example-url-icon">{example.icon}</span>
              <span className="example-url-label">{example.label}</span>
            </button>
          ))}
        </div>
      </div>

      <div className="mt-4">
        <p className="signup-hint">
          Want to save your links?{' '}
          <Link
            to="/register"
            className="text-blue-600 hover:text-blue-700 font-medium"
          >
            Create an account
          </Link>
        </p>
      </div>
    </div>
  );
});

const FeaturesSection = () => (
  <div className="features-section">
    <div className="feature-card">
      <BookOpen className="feature-icon" />
      <h3 className="feature-title">Smart Summaries</h3>
      <p className="feature-description">
        Save hours of reading time with AI-powered summaries that capture key points.
      </p>
    </div>

    <div className="feature-card">
      <Newspaper className="feature-icon" />
      <h3 className="feature-title">Related News</h3>
      <p className="feature-description">
        Discover relevant news and articles tailored to your interests.
        Never miss important connections.
      </p>
    </div>

    <div className="feature-card">
      <Share2 className="feature-icon" />
      <h3 className="feature-title">Easy Sharing</h3>
      <p className="feature-description">
        Share insights with your team in one click. Perfect for researchers,
        analysts, and curious minds.
      </p>
    </div>
  </div>
);

const ExamplesSection = () => {
  return (
    <div className="examples-section">
      <h2 className="section-title">See It In Action</h2>
      <div style={{
        position: 'relative',
        boxSizing: 'content-box',
        maxHeight: '80svh',
        width: '100%',
        aspectRatio: '1.9699042407660738',
        padding: '40px 0 40px 0'
      }}>
        <iframe 
          src="https://app.supademo.com/embed/cm3s3j8hw10we3vuksbtr1tid?embed_v=2"
          loading="lazy"
          title="Linksort Demo"
          allow="clipboard-write"
          sandbox="allow-scripts allow-same-origin allow-popups allow-forms"
          frameBorder="0"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        />
      </div>
    </div>
  );
};

const SignupSection = ({ email, setEmail, error, isSubscribing, handleEmailSubmit, navigate, formRef, setUrl, exampleUrls, isAuthReady }) => (
  <div className="signup-section">
    <h2 className="section-title">Get Early Access</h2>
    <p className="section-description">
      Join users who are already transforming how they process web content.
    </p>
    
    <div className="signup-container">
      <div className="buttons-wrapper">
        <button
          onClick={() => handleRandomExample(exampleUrls, setUrl, formRef)}
          className="signup-btn primary"
        >
          <div className="btn-content">
            <Rocket className="btn-icon" />
            <span className="btn-text">Start Free Trial</span>
          </div>
        </button>

        <span className="text-sm text-gray-500">or</span>

        <button
          onClick={() => navigate('/login')}
          className="signup-btn secondary"
        >
          <div className="btn-content">
            <LogIn className="btn-icon" />
            <span className="btn-text">Sign In to Your Account</span>
          </div>
        </button>
      </div>

      <div className="benefits-grid">
        <div className="benefit-item">
          <div className="benefit-content">
            <Check className="check-icon" />
            <span className="benefit-text">Free plan available</span>
          </div>
        </div>
        <div className="benefit-item">
          <div className="benefit-content">
            <Check className="check-icon" />
            <span className="benefit-text">No credit card required</span>
          </div>
        </div>
        <div className="benefit-item">
          <div className="benefit-content">
            <Check className="check-icon" />
            <span className="benefit-text">Access to core features</span>
          </div>
        </div>
      </div>

      <div className="pro-plans-link">
        Need more summaries? Check out our{' '}
        <Link to="/pricing" className="text-blue-600 hover:text-blue-700 font-medium">
          Pro plans
        </Link>
      </div>
    </div>
  </div>
);

const Footer = () => {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">
          <Link to="/features" className="footer-link">Features</Link>
          <span className="footer-separator">·</span>
          <Link to="/help" className="footer-link">Help Center</Link>
          <span className="footer-separator">·</span>
          <Link to="/contact" className="footer-link">Contact</Link>
          <span className="footer-separator">·</span>
          <Link to="/privacy-policy" className="footer-link">Privacy Policy</Link>
          <span className="footer-separator">·</span>
          <Link to="/terms-of-service" className="footer-link">Terms of Service</Link>
          <span className="footer-separator">·</span>
          <Link to="/refund-policy" className="footer-link">Refund Policy</Link>
        </div>
        <div className="footer-copyright">
          © {currentYear} LinkSort.ai by DIGTECH AI LTD. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

const Landing = () => {
  const navigate = useNavigate();
  const isSubmitting = useRef(false);
  const [isAuthReady, setIsAuthReady] = useState(false);
  const { user, loading, isInitialized } = useAuth();
  
  const [state, setState] = useState({
    url: '',
    email: '',
    isLoading: false,
    isSubscribing: false,
    error: ''
  });

  const { url, email, isLoading, isSubscribing, error } = state;

  // 监听认证状态变化，但不阻塞渲染
  useEffect(() => {
    if (isInitialized) {
      setIsAuthReady(true);
    }
  }, [isInitialized]);

  // URL 验证函数
  const validateUrl = useCallback((inputUrl) => {
    console.log('[Landing] Validating URL:', inputUrl);
    if (!inputUrl.trim()) {
      return 'Please enter a URL';
    }
    try {
      new URL(inputUrl);
      return null;
    } catch {
      return 'Please enter a valid URL';
    }
  }, []);

  // 移除 initialized 和 mountedRef，简化初始化逻辑
  useEffect(() => {
    const pendingUrl = sessionStorage.getItem('pendingUrl');
    if (pendingUrl) {
      setState(prev => ({ ...prev, url: pendingUrl }));
      sessionStorage.removeItem('pendingUrl');
    }
  }, []);

  const handleUrlSubmit = useCallback(async (e) => {
    e.preventDefault();
    console.log('[Landing] URL submission triggered:', { url, isSubmitting: isSubmitting.current });

    if (isSubmitting.current) {
      console.log('[Landing] Submission blocked - already submitting');
      return;
    }

    // 如果 URL 为空，不进行验证，因为我们会在 onSubmit 中处理
    if (!url.trim()) {
      return;
    }

    const urlError = validateUrl(url);
    if (urlError) {
      console.log('[Landing] URL validation failed:', urlError);
      setState(prev => ({ ...prev, error: urlError }));
      return;
    }

    try {
      isSubmitting.current = true;
      setState(prev => ({ ...prev, isLoading: true, error: '' }));
      console.log('[Landing] Storing URL and navigating:', url);
      
      sessionStorage.setItem('pendingUrl', url);
      setTimeout(() => {
        navigate('/app');
      }, 100);
      
    } catch (err) {
      console.error('[Landing] Submission error:', err);
      setState(prev => ({
        ...prev,
        error: err.message || 'An error occurred',
        isLoading: false
      }));
      sessionStorage.removeItem('pendingUrl');
    } finally {
      isSubmitting.current = false;
    }
  }, [url, navigate, validateUrl]);

  // 状态更新处理器
  const setUrl = useCallback((newUrl) => {
    setState(prev => ({ ...prev, url: newUrl }));
  }, []);

  const setEmail = useCallback((newEmail) => {
    setState(prev => ({ ...prev, email: newEmail }));
  }, []);

  // Email 处理
  const handleEmailSubmit = useCallback(async (e) => {
    e.preventDefault();
    if (!email.includes('@')) {
      setState(prev => ({ ...prev, error: 'Please enter a valid email address' }));
      return;
    }

    setState(prev => ({ ...prev, isSubscribing: true, error: '' }));
    
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setState(prev => ({ ...prev, email: '', isSubscribing: false }));
    } catch (err) {
      setState(prev => ({
        ...prev,
        error: 'Failed to join beta. Please try again.',
        isSubscribing: false
      }));
    }
  }, [email]);

  // 记录状态变化
  useEffect(() => {
    console.log('[Landing] State updated:', { url, isLoading, error });
  }, [url, isLoading, error]);

  const formRef = useRef(null);
  const exampleUrls = useMemo(() => [
    {
      url: 'https://www.sciencedaily.com/releases/2025/01/250117171309.htm',
      label: 'Science Article',
      icon: '🧬'
    },
    {
      url: 'https://www.economist.com/leaders/2024/11/14/whats-about-to-hit-the-world-economy',
      label: 'Economics Article',
      icon: '📈'
    },
    {
      url: 'https://techcrunch.com/2024/11/22/chatgpt-everything-to-know-about-the-ai-chatbot/',
      label: 'Tech News',
      icon: '💻'
    }
  ], []);

  // 修改认证按钮渲染逻辑
  const renderAuthButton = () => {
    // 移除 isAuthReady 检查，直接返回按钮
    return (
      <>
        <button 
          className="nav-btn outline" 
          onClick={() => navigate('/login')}
        >
          <div className="btn-content">
            <LogIn className="btn-icon" />
            <span className="btn-text">Sign In</span>
          </div>
        </button>
        <button 
          className="nav-btn primary" 
          onClick={() => handleRandomExample(exampleUrls, setUrl, formRef)}
        >
          <div className="btn-content">
            <Rocket className="btn-icon" />
            <span className="btn-text">Start Free Trial</span>
          </div>
        </button>
      </>
    );
  };

  return (
    <div className="landing-page">
      <Navigation 
        navigate={navigate} 
        formRef={formRef} 
        setUrl={setUrl} 
        exampleUrls={exampleUrls}
        isAuthReady={isAuthReady}
        renderAuthButton={renderAuthButton}
      />
      <main className="main-content">
        <HeroSection />
        <DemoSection
          url={url}
          setUrl={setUrl}
          isLoading={isLoading}
          error={error}
          handleUrlSubmit={handleUrlSubmit}
          exampleUrls={exampleUrls}
          formRef={formRef}
          isAuthReady={isAuthReady}
        />
        <FeaturesSection />
        <ExamplesSection />
        <SignupSection
          email={email}
          setEmail={setEmail}
          error={error}
          isSubscribing={isSubscribing}
          handleEmailSubmit={handleEmailSubmit}
          navigate={navigate}
          formRef={formRef}
          setUrl={setUrl}
          exampleUrls={exampleUrls}
          isAuthReady={isAuthReady}
        />
      </main>
      <Footer />
    </div>
  );
};

export default Landing;