// components/indexeddb.js
import { addToSyncQueue } from './syncService';

function openDatabase() {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open("EmbeddingDatabase", 7);

        request.onupgradeneeded = function(event) {
            const db = event.target.result;
            const stores = {
                embeddings: "url",
                categoryembeddings: "category",
                queries: "url",
                favorites: "url"
            };
            
            Object.entries(stores).forEach(([storeName, keyPath]) => {
                if (!db.objectStoreNames.contains(storeName)) {
                    const store = db.createObjectStore(storeName, { keyPath });
                    
                    if (storeName === 'favorites') {
                        store.createIndex('main_image_url', 'main_image_url', { unique: false });
                    }
                }
            });
        };

        request.onsuccess = function(event) {
            resolve(event.target.result);
        };

        request.onerror = function(event) {
            reject("Error opening IndexedDB: " + event.target.errorCode);
        };
    });
}

function clearObjectStore(store) {
    return new Promise((resolve, reject) => {
        const request = store.clear();
        request.onsuccess = () => resolve();
        request.onerror = (event) => reject("Error clearing store: " + event.target.errorCode);
    });
}

export function saveFavoritesToIndexedDB(favorites) {
    return new Promise(async (resolve, reject) => {
        try {
            // 1. 先获取现有收藏
            const existingFavorites = await getFavoritesFromIndexedDB();
            console.log('Existing favorites in IndexedDB:', existingFavorites.map(fav => ({
                url: fav.url,
                title: fav.title,
                created_at: fav.created_at,
                updated_at: fav.updated_at
            })));
            const existingFavoritesMap = new Map(
                existingFavorites.map(fav => [fav.url, fav])
            );

            // 2. 开启新的事务进行更新
            const db = await openDatabase();
            const transaction = db.transaction(["favorites"], "readwrite");
            const store = transaction.objectStore("favorites");

            // 不再清除存储，而是逐个更新
            const promises = favorites.map(fav => {
                return new Promise((res, rej) => {
                    // 检查是否存在现有的收藏
                    const existingFav = existingFavoritesMap.get(fav.url);
                    
                    let finalFav = { ...fav };
                    
                    if (existingFav) {
                        // 检查是否有任何字段不同
                        const hasChanges = 
                            existingFav.title !== fav.title ||
                            existingFav.summary !== fav.summary ||
                            existingFav.category !== fav.category ||
                            existingFav.categoryFromGPT !== fav.categoryFromGPT ||
                            existingFav.main_image_url !== fav.main_image_url ||
                            existingFav.updated_at !== fav.updated_at ||
                            JSON.stringify(existingFav.keyPoints) !== JSON.stringify(fav.keyPoints);

                        if (hasChanges) {
                            console.log(`Updating favorite ${fav.url} with new data`);
                            finalFav = {
                                ...fav,
                                main_image_url: fav.main_image_url || null,
                                updated_at: fav.updated_at || fav.created_at
                            };
                        } else {
                            console.log(`No changes for ${fav.url}, keeping existing data`);
                            finalFav = existingFav;
                        }
                    } else {
                        console.log(`Adding new favorite: ${fav.url}`);
                        finalFav = {
                            ...fav,
                            main_image_url: fav.main_image_url || null,
                            updated_at: fav.updated_at || fav.created_at
                        };
                    }

                    const request = store.put(finalFav);
                    request.onsuccess = () => res();
                    request.onerror = (error) => rej(error);
                });
            });

            // 等待所有更新完成
            try {
                await Promise.all(promises);
                console.log('All favorites successfully saved to IndexedDB');
            } catch (error) {
                console.error('Error while saving favorites:', error);
                throw error;
            }

            // 等待事务完成
            await new Promise((res, rej) => {
                transaction.oncomplete = () => {
                    console.log('Transaction completed successfully');
                    res();
                };
                transaction.onerror = () => {
                    console.error('Transaction failed:', transaction.error);
                    rej(transaction.error);
                };
            });

            resolve(favorites);
        } catch (error) {
            console.error('Error in saveFavoritesToIndexedDB:', error);
            reject(error);
        }
    });
}

export function getFavoritesFromIndexedDB() {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(['favorites'], 'readonly');
            const store = transaction.objectStore('favorites');

            const getAllRequest = store.getAll();
            getAllRequest.onsuccess = (event) => resolve(event.target.result || []);
            getAllRequest.onerror = (event) => reject('Error fetching favorites: ' + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveEmbeddingToIndexedDB(url, embedding) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["embeddings"], "readwrite");
            const store = transaction.objectStore("embeddings");

            const request = store.put({ url, embedding });
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'embeddings',
                    operation: 'update',
                    data: { url, embedding }
                });
                resolve();
            };
            request.onerror = (event) => reject("Error storing embedding: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveCategoryEmbeddingToIndexedDB(category, embedding) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readwrite");
            const store = transaction.objectStore("categoryembeddings");

            // 首先获取现有的数据
            const getRequest = store.get(category);
            
            getRequest.onsuccess = () => {
                const existingData = getRequest.result || {};
                const dataToStore = {
                    category: category,
                    embedding: embedding,
                    icon_url: existingData.icon_url || null
                };

                const putRequest = store.put(dataToStore);
                putRequest.onsuccess = () => {
                    addToSyncQueue({
                        type: 'categoryembeddings',
                        operation: 'update',
                        data: { category, embedding }
                    });
                    resolve();
                };
                putRequest.onerror = (event) => reject("Error storing category embedding: " + event.target.errorCode);
            };
            
            getRequest.onerror = (event) => reject("Error getting existing category data: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function getEmbeddingFromIndexedDB(url) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["embeddings"], "readonly");
            const store = transaction.objectStore("embeddings");

            const getRequest = store.get(url);
            getRequest.onsuccess = (event) => resolve(event.target.result ? event.target.result.embedding : null);
            getRequest.onerror = (event) => reject("Error fetching embedding: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function getCategoryEmbeddingFromIndexedDB(category) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readonly");
            const store = transaction.objectStore("categoryembeddings");

            const getRequest = store.get(category);
            getRequest.onsuccess = (event) => resolve(event.target.result ? event.target.result.embedding : null);
            getRequest.onerror = (event) => reject("Error fetching category embedding: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function getCategoriesFromIndexedDB() {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readonly");
            const store = transaction.objectStore("categoryembeddings");

            const getAllRequest = store.getAll();
            getAllRequest.onsuccess = (event) => resolve(event.target.result || []);
            getAllRequest.onerror = (event) => reject("Error fetching categories: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveQueryToIndexedDB(url, title, category, query) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["queries"], "readwrite");
            const store = transaction.objectStore("queries");

            if (!url || !query) {
                reject("Error: Invalid data for storing in IndexedDB.");
                return;
            }

            const data = { url, title, category, query };
            const request = store.put(data);
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'queries',
                    operation: 'update',
                    data
                });
                resolve();
            };
            request.onerror = (event) => reject("Error storing query: " + event.target.errorCode);
        } catch (error) {
            reject("Error saving query to IndexedDB: " + error);
        }
    });
}

export function getQueryFromIndexedDB(url) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["queries"], "readonly");
            const store = transaction.objectStore("queries");

            const getRequest = store.get(url);
            getRequest.onsuccess = (event) => resolve(event.target.result ? event.target.result.query : null);
            getRequest.onerror = (event) => reject("Error fetching query: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function deleteFavoriteFromIndexedDB(url) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["favorites"], "readwrite");
            const store = transaction.objectStore("favorites");

            const request = store.delete(url);
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'favorites',
                    operation: 'delete',
                    data: { url }
                });
                resolve();
            };
            request.onerror = (event) => reject("Error deleting favorite: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveCategoryToIndexedDB(categoryData) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readwrite");
            const store = transaction.objectStore("categoryembeddings");

            // 确保数据格式正确
            let dataToStore;
            if (typeof categoryData === 'string') {
                // 如果输入是字符串，创建基本对象
                dataToStore = {
                    category: categoryData,
                    embedding: null,
                    icon_url: null
                };
            } else {
                // 如果输入是对象，确保有正确的 category 字段
                dataToStore = {
                    category: categoryData.category || categoryData.name, // 使用 category 或 name
                    embedding: categoryData.embedding || null,
                    icon_url: categoryData.icon_url || null
                };
            }

            // 验证 category 字段存在
            if (!dataToStore.category) {
                throw new Error('Category field is required');
            }

            const request = store.put(dataToStore);
            
            request.onsuccess = () => {
                addToSyncQueue({
                    type: 'categories',
                    operation: 'create',
                    data: { category: dataToStore.category }
                });
                resolve();
            };
            request.onerror = (event) => reject("Error storing category: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function saveCategoriestoIndexedDB(categories) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readwrite");
            const store = transaction.objectStore("categoryembeddings");

            // 首先清除现有数据
            await clearObjectStore(store);

            // 使用Promise.all处理所有保存操作
            const promises = categories.map(categoryData => {
                return new Promise((res, rej) => {
                    // 确保数据格式正确
                    const category = typeof categoryData === 'string' 
                        ? categoryData 
                        : (categoryData.name || categoryData.category);
                        
                    // 构建要存储的对象
                    const dataToStore = {
                        category: category,  // 使用 category 作为 keyPath
                        embedding: null,     // 初始化 embedding 为 null
                        icon_url: categoryData.icon_url || null  // 保存图标URL（如果有的话）
                    };

                    const request = store.put(dataToStore);
                    request.onsuccess = () => res();
                    request.onerror = () => rej(request.error);
                });
            });

            await Promise.all(promises);
            
            // 添加到同步队列
            addToSyncQueue({
                type: 'categories',
                operation: 'update',
                data: categories
            });

            transaction.oncomplete = () => resolve();
            transaction.onerror = () => reject(transaction.error);

        } catch (error) {
            reject(error);
        }
    });
}

export function deleteCategoryFromIndexedDB(categoryName, skipSync = false) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readwrite");
            const store = transaction.objectStore("categoryembeddings");

            const request = store.delete(categoryName);
            
            request.onsuccess = () => {
                // 只有在不是同步过程中的删除才添加到同步队列
                if (!skipSync) {
                    addToSyncQueue({
                        type: 'categories',
                        operation: 'delete',
                        data: { category: categoryName }
                    });
                }
                resolve();
            };
            request.onerror = (event) => reject("Error deleting category: " + event.target.errorCode);
        } catch (error) {
            reject(error);
        }
    });
}

export function getCategoryFromIndexedDB(categoryName) {
    return new Promise(async (resolve, reject) => {
        try {
            const db = await openDatabase();
            const transaction = db.transaction(["categoryembeddings"], "readonly");
            const store = transaction.objectStore("categoryembeddings");

            const request = store.get(categoryName);

            request.onsuccess = () => {
                resolve(request.result);  // 返回完整的分类对象，包含 category、embedding 和 icon_url
            };

            request.onerror = () => {
                reject(request.error);
            };
        } catch (error) {
            reject(error);
        }
    });
}