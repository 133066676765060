// src/components/ProcessingSteps.js

import React from 'react';

export const PROCESSING_STEPS = {
    ANALYZING: {
        message: 'Analyzing article content',
        description: 'Extracting text from webpage',
    },
    PROCESSING: {
        message: 'Processing content',
        description: 'Identifying key information',
    },
    GENERATING: {
        message: 'Generating summary',
        description: 'Creating initial draft',
    },
    CATEGORIZING: {
        message: 'Processing category',
        description: 'Analyzing content for categorization',
    },
    SAVING: {
        message: 'Saving article',
        description: 'Storing information locally',
    }
};

export const updateProcessingSteps = (currentSteps, newStep, status) => {
    const stepIndex = currentSteps.findIndex(step => step.message === newStep.message);
    
    if (stepIndex === -1) {
        // 添加新步骤
        return [...currentSteps, { ...newStep, status }];
    } else {
        // 更新现有步骤
        return currentSteps.map((step, index) => {
            if (index === stepIndex) {
                return { ...step, status };
            }
            return step;
        });
    }
};

export const createProcessingStep = async (setProcessingSteps, step, action) => {
    // 设置当前步骤为处理中
    setProcessingSteps(prev => updateProcessingSteps(prev, step, 'processing'));
    
    try {
        // 执行操作
        const result = await action();
        
        // 设置当前步骤为完成
        setProcessingSteps(prev => updateProcessingSteps(prev, step, 'completed'));
        
        return result;
    } catch (error) {
        // 如果发生错误，也将步骤标记为完成，但可以添加错误状态
        setProcessingSteps(prev => updateProcessingSteps(prev, 
            { ...step, description: `Error: ${error.message}` }, 
            'error'
        ));
        throw error;
    }
};

const ProcessingSteps = ({ steps }) => {
    return (
        <div className="processing-steps">
            {steps.map((step, index) => (
                <div key={index} className={`processing-step ${step.status}`}>
                    <div className="step-icon">
                        {step.status === 'completed' && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M20 6L9 17l-5-5"/>
                            </svg>
                        )}
                        {step.status === 'processing' && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48l2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48l2.83-2.83"/>
                            </svg>
                        )}
                    </div>
                    <div className="step-content">
                        <div className="step-message">{step.message}</div>
                        {step.description && (
                            <div className="step-description">{step.description}</div>
                        )}
                        {step.status === 'processing' && (
                            <div className="progress-bar"></div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ProcessingSteps; 