// components/HelpCenter.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { 
  BookOpen,
  CreditCard,
  MessageCircle,
  Mail,
  ExternalLink
} from "lucide-react";

const HelpCenter = () => {
  const categories = [
    {
      icon: <BookOpen className="w-6 h-6" />,
      title: "Getting Started",
      description: "Learn the basics of using LinkSort.ai",
      articles: [
        {
          title: "Creating Your Account",
          link: "/register"
        },
        {
          title: "Basic Features Overview",
          link: "/features"
        }
      ]
    },
    {
      icon: <CreditCard className="w-6 h-6" />,
      title: "Account & Billing",
      description: "Manage your account and subscription",
      articles: [
        {
          title: "Subscription Plans",
          link: "/pricing"
        },
        {
          title: "Refund Policy",
          link: "/refund-policy"
        }
      ]
    }
  ];

  const faqs = [
    {
      question: "What is LinkSort.ai?",
      answer: "LinkSort.ai is an AI-powered link management tool that helps you organize, summarize, and discover web content. It uses advanced AI to automatically generate summaries of articles and find related content."
    },
    {
      question: "How does the AI summarization and categorization work?",
      answer: "Our AI automatically analyzes and categorizes your content, organizing links into relevant topics and collections. It generates concise summaries highlighting key points, making it easy to review and find information later. The system learns from your preferences to improve categorization over time."
    },
    {
      question: "Can I access my links across different devices?",
      answer: "Yes! LinkSort.ai offers seamless cross-device synchronization. Your links, summaries, and collections are instantly available on all your devices - desktop, mobile, or tablet. Changes made on one device automatically sync across all others."
    },
    {
      question: "How does the news recommendation system work?",
      answer: "Our AI analyzes your saved content to understand your interests and automatically recommends relevant news articles and similar content. You'll discover new perspectives and stay updated on topics that matter to you, all personalized based on your reading patterns."
    },
    {
      question: "Is there a free plan available?",
      answer: "Yes! We offer a free plan that includes core features like AI summarization, basic link management, and content recommendations. You can try LinkSort.ai without any commitment or credit card required."
    },
    {
      question: "How secure is my data?",
      answer: "We take security seriously. All data is encrypted in transit and at rest, and we never share your personal information with third parties. You have full control over your saved links, summaries, and preferences."
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-6xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold mb-4">Help Center</h1>
          <p className="text-xl text-gray-600">Find answers to your questions and learn how to get the most out of LinkSort.ai</p>
        </div>
        
        {/* Categories Grid */}
        <div className="grid md:grid-cols-2 gap-8 mb-16">
          {categories.map((category, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-xl shadow-md hover:shadow-lg transition-shadow"
            >
              <div className="text-blue-600 mb-4">{category.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{category.title}</h3>
              <p className="text-gray-600 mb-4">{category.description}</p>
              <ul className="space-y-2">
                {category.articles.map((article, idx) => (
                  <li key={idx}>
                    <Link 
                      to={article.link}
                      className="text-blue-600 hover:text-blue-700 flex items-center"
                    >
                      {article.title}
                      <ExternalLink className="w-4 h-4 ml-1" />
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* FAQs */}
        <div className="bg-white rounded-xl shadow-lg p-8 mb-16">
          <h2 className="text-2xl font-semibold mb-6 text-center">Frequently Asked Questions</h2>
          <div className="max-w-4xl mx-auto space-y-6">
            {faqs.map((faq, index) => (
              <div key={index} className="border-b pb-6 last:border-b-0">
                <h3 className="text-lg font-medium mb-2">{faq.question}</h3>
                <p className="text-gray-600">{faq.answer}</p>
              </div>
            ))}
          </div>
        </div>

        {/* Contact Support */}
        <div className="text-center bg-white rounded-xl shadow-lg p-8 max-w-2xl mx-auto">
          <h2 className="text-2xl font-semibold mb-4">Still need help?</h2>
          <p className="text-gray-600 mb-6">Our support team is here to assist you 24/7.</p>
          <div className="space-x-4">
            <Link 
              to="/contact" 
              className="inline-flex items-center bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition-colors"
            >
              <MessageCircle className="w-5 h-5 mr-2" />
              Chat with Support
            </Link>
            <Link 
              to="mailto:support@linksort.ai" 
              className="inline-flex items-center border border-blue-600 text-blue-600 px-6 py-3 rounded-lg hover:bg-blue-50 transition-colors"
            >
              <Mail className="w-5 h-5 mr-2" />
              Email Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HelpCenter;