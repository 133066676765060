// components/MainLayout.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { BookmarkIcon, NewspaperIcon, CheckCircle } from 'lucide-react';
import UrlForm from './UrlForm';
import FavoritesList from './FavoritesList';
import NewsList from './NewsList';
import '../css/MainLayout.css';
import { handleCreateCategory } from './categoryUtils';
import { getCategoriesFromIndexedDB } from './indexeddb';
import { useLocation } from 'react-router-dom';
import { useToast } from '../components/Toast';

const MainLayout = ({
    url,
    setUrl,
    handleSubmit,
    loading,
    processingSteps,
    favorites,
    setFavorites,        
    setFilteredFavorites, 
    categories,
    filterFavoritesByCategory,
    setCategories,
    expandedItemUrl,
    setExpandedItemUrl
}) => {
    console.log('🏗️ MainLayout rendering with categories:', categories);
    
    const [activeView, setActiveView] = useState(null);  // null for split view
    const [activeCategory, setActiveCategory] = useState(null);
    const handleSubmitRef = useRef(handleSubmit);
    const location = useLocation();
    const [successMessage, setSuccessMessage] = useState('');
    const { showToast } = useToast();
    const toastShownRef = useRef(false);  // 添加一个 ref 来追踪是否已显示过 toast

    // URL 处理逻辑
    useEffect(() => {
        handleSubmitRef.current = handleSubmit;
    }, [handleSubmit]);

    const handlePendingUrl = useCallback(() => {
        const pendingUrl = sessionStorage.getItem('pendingUrl');
        if (pendingUrl && handleSubmitRef.current) {
            setUrl(pendingUrl);
            sessionStorage.removeItem('pendingUrl');
            setTimeout(() => {
                handleSubmitRef.current({ preventDefault: () => {} });
            }, 100);
        }
    }, [setUrl]);

    useEffect(() => {
        if (handleSubmitRef.current) {
            handlePendingUrl();
        }
    }, [handlePendingUrl]);

    // 分类管理
    const handleCategoryClick = useCallback((cat) => {
        setActiveCategory(prevCat => {
            const newCat = prevCat === cat ? null : cat;
            filterFavoritesByCategory(newCat);
            return newCat;
        });
    }, [filterFavoritesByCategory]);

    const clearCategorySelection = useCallback((e) => {
        e?.stopPropagation();
        setActiveCategory(null);
        filterFavoritesByCategory(null);
    }, [filterFavoritesByCategory]);

    const getCategoryColor = useCallback(() => {
        return 'rgb(255, 255, 255)'; // 白色背景
    }, []);

    useEffect(() => {
        if (!activeView || activeView === 'news') {
            setExpandedItemUrl(null);
        }
    }, [activeView, setExpandedItemUrl]);

    const toggleExpand = useCallback((url) => {
        if (expandedItemUrl === url) {
            const expandedElement = document.querySelector(`[data-url="${url}"]`);
            if (expandedElement) {
                const elementTop = expandedElement.getBoundingClientRect().top;
                
                setExpandedItemUrl(null);
                
                requestAnimationFrame(() => {
                    const collapsedElement = document.querySelector(`[data-url="${url}"]`);
                    if (collapsedElement) {
                        const newTop = collapsedElement.getBoundingClientRect().top;
                        const scrollAdjustment = newTop - elementTop;
                        
                        window.scrollBy({
                            top: scrollAdjustment,
                            behavior: 'smooth'
                        });
                    }
                });
            }
        } else {
            setExpandedItemUrl(url);
            setActiveView('saved');
            
            setTimeout(() => {
                const expandedElement = document.querySelector('.expanded-item-container');
                if (expandedElement) {
                    expandedElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    });
                }
            }, 100);
        }
    }, [expandedItemUrl, setExpandedItemUrl, setActiveView]);

    const handleNewCategory = async (categoryName) => {
        const newCategory = await handleCreateCategory(categoryName, categories);
        if (newCategory) {
            // 立即更新分类列表
            setCategories(prevCategories => [...prevCategories, categoryName]);
            // 可能需要重新获取分类数据
            const updatedCategories = await getCategoriesFromIndexedDB();
            if (typeof setCategories === 'function') {
                setCategories(updatedCategories.map(cat => cat.category));
            }
        }
        return newCategory;
    };

    useEffect(() => {
        console.log('🔄 MainLayout: URL changed', location.search);
        const params = new URLSearchParams(location.search);
        
        if (params.get('verification_success')) {
            // 检查 sessionStorage 中是否已经显示过提示
            const hasShownToast = sessionStorage.getItem('verification_toast_shown');
            
            if (!hasShownToast) {
                const message = decodeURIComponent(params.get('message')) || 'Email verified successfully!';
                console.log('✨ MainLayout: Showing success toast:', message);
                
                showToast({
                    type: 'success',
                    message: message,
                    duration: 3000
                });
                
                // 在 sessionStorage 中标记已显示
                sessionStorage.setItem('verification_toast_shown', 'true');
            }
            
            // 清除 URL 参数
            const newUrl = window.location.pathname;
            window.history.replaceState({}, '', newUrl);
        }
    }, [location, showToast]);

    return (
        <div className="main-layout">
            {/* URL Input Section */}
            <div className="card">
                <div className="card-content">
                    <UrlForm 
                        url={url} 
                        setUrl={setUrl} 
                        handleSubmit={handleSubmit} 
                        loading={loading} 
                    />
                    {loading && (
                        <div className="processing-steps">
                            {processingSteps.map((step, index) => (
                                <div key={index} className={`processing-step ${step.status}`}>
                                    <div className="step-icon">
                                        {step.status === 'completed' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                                <path d="M20 6L9 17l-5-5"/>
                                            </svg>
                                        )}
                                        {step.status === 'processing' && (
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                                <path d="M12 2v4m0 12v4M4.93 4.93l2.83 2.83m8.48 8.48l2.83 2.83M2 12h4m12 0h4M4.93 19.07l2.83-2.83m8.48-8.48l2.83-2.83"/>
                                            </svg>
                                        )}
                                    </div>
                                    <div className="step-content">
                                        <div className="step-message">{step.message}</div>
                                        {step.description && (
                                            <div className="step-description">{step.description}</div>
                                        )}
                                        {step.status === 'processing' && (
                                            <div className="progress-bar"></div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>

            {/* Navigation */}
            <div className="nav-tabs">
                <div className="tabs-container">
                    <button 
                        className={`tab-button ${activeView === 'saved' ? 'active' : ''}`}
                        onClick={() => setActiveView(activeView === 'saved' ? null : 'saved')}
                    >
                        <BookmarkIcon className="tab-icon" />
                        <span>Saved Articles</span>
                    </button>
                    <button 
                        className={`tab-button ${activeView === 'news' ? 'active' : ''}`}
                        onClick={() => setActiveView(activeView === 'news' ? null : 'news')}
                    >
                        <NewspaperIcon className="tab-icon" />
                        <span>Related News</span>
                    </button>
                </div>
            </div>

            {/* Content Area */}
            <div className={`content-grid ${activeView ? 'single-column' : 'split-view'}`}>
                {/* Saved Articles */}
                {(!activeView || activeView === 'saved') && (
                    <div className="card content-card">
                        <FavoritesList 
                            favorites={favorites} 
                            setFavorites={setFavorites}
                            setFilteredFavorites={setFilteredFavorites} 
                            getCategoryColor={getCategoryColor}
                            categories={categories}
                            activeCategory={activeCategory}
                            handleCategoryClick={handleCategoryClick}
                            clearCategorySelection={clearCategorySelection}
                            setActiveView={setActiveView}
                            activeView={activeView}
                            expandedItemUrl={expandedItemUrl}
                            setExpandedItemUrl={setExpandedItemUrl}
                            toggleExpand={toggleExpand}
                            handleCreateCategory={handleNewCategory}
                            setCategories={setCategories}
                        />
                    </div>
                )}

                {/* Related News */}
                {(!activeView || activeView === 'news') && (
                    <div className="card content-card">
                        <NewsList />
                    </div>
                )}
            </div>
        </div>
    );
};

export default MainLayout;