// components/utils/TawkMessenger.js

import { useEffect } from 'react';

const TawkMessenger = () => {
  useEffect(() => {
    // 添加预连接和 DNS 预获取
    const addPreconnect = () => {
      const head = document.head;
      
      // 添加 preconnect
      const preconnect = document.createElement('link');
      preconnect.rel = 'preconnect';
      preconnect.href = 'https://embed.tawk.to';
      head.appendChild(preconnect);
      
      // 添加 dns-prefetch
      const dnsPrefetch = document.createElement('link');
      dnsPrefetch.rel = 'dns-prefetch';
      dnsPrefetch.href = 'https://embed.tawk.to';
      head.appendChild(dnsPrefetch);

      return () => {
        head.removeChild(preconnect);
        head.removeChild(dnsPrefetch);
      };
    };

    // 创建 Tawk_API 对象
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // 扩展配置选项
    window.Tawk_API.customStyle = {
      passive: true,
      zIndex: 1000,
      // 添加更多性能相关配置
      features: {
        enableHighPerformance: true,
        usePassiveEvents: true,
        disableAnimation: false,
        smoothScroll: true
      },
      // 添加移动端优化
      mobile: {
        optimizeTouch: true,
        disableZoomOnFocus: true
      }
    };

    // 添加性能监控
    window.Tawk_API.onLoad = function() {
      console.log('Tawk.to loaded successfully');
      window.Tawk_API.isReady = true;
      
      // 性能监控
      if (window.performance && window.performance.mark) {
        window.performance.mark('tawk-loaded');
        window.performance.measure('tawk-load-time', 'navigationStart', 'tawk-loaded');
      }
      
      window.Tawk_API.maximize();
    };

    // 预加载标志，避免重复加载
    if (window.Tawk_API.isReady) {
      window.Tawk_API.maximize();
      return;
    }

    // 构建 Tawk.to URL
    const tawkToId = process.env.REACT_APP_TAWK_TO_ID;
    const tawkToKey = process.env.REACT_APP_TAWK_TO_KEY;
    
    // 检查是否有值
    if (!tawkToId || !tawkToKey) {
      console.error('Tawk.to credentials are missing!');
      return;
    }

    const tawkToUrl = `https://embed.tawk.to/${tawkToId}/${tawkToKey}`;

    // 添加预连接
    const cleanup = addPreconnect();

    // 优化脚本加载
    const loadScript = () => {
      // 创建 Web Worker 来加载脚本
      const workerBlob = new Blob([`
        self.onmessage = function(e) {
          fetch(e.data)
            .then(response => response.text())
            .then(content => {
              self.postMessage(content);
            })
            .catch(error => {
              self.postMessage({ error: error.message });
            });
        };
      `], { type: 'application/javascript' });

      const worker = new Worker(URL.createObjectURL(workerBlob));
      
      worker.onmessage = function(e) {
        if (e.data.error) {
          console.error('Error loading Tawk.to:', e.data.error);
          return;
        }
        
        const script = document.createElement('script');
        script.textContent = e.data;
        script.async = true;
        document.head.appendChild(script);
        
        // 清理 worker
        worker.terminate();
        URL.revokeObjectURL(workerBlob);
      };

      worker.postMessage(tawkToUrl);
    };

    const loadWithRetry = (retries = 3) => {
      const load = async () => {
        try {
          await loadScript();
        } catch (error) {
          console.error(`Failed to load Tawk.to (attempts left: ${retries}):`, error);
          if (retries > 0) {
            setTimeout(() => loadWithRetry(retries - 1), 2000);
          }
        }
      };
      
      load();
    };

    if ('requestIdleCallback' in window) {
      requestIdleCallback(() => loadWithRetry(), { timeout: 200 });
    } else {
      setTimeout(() => loadWithRetry(), 0);
    }

    const loadTawk = () => {
      // 原有的加载逻辑
    };

    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        loadTawk();
        observer.disconnect();
      }
    });

    // 创建一个占位元素来观察
    const placeholder = document.createElement('div');
    placeholder.id = 'tawk-placeholder';
    document.body.appendChild(placeholder);
    observer.observe(placeholder);

    // 组件卸载时清理
    return () => {
      // 清理预连接标记
      cleanup();

      // 移除 tawk.to 脚本
      const tawkScript = document.querySelector('script[src*="tawk.to"]');
      if (tawkScript) {
        tawkScript.remove();
      }
      // 清理标志
      if (window.Tawk_API) {
        window.Tawk_API.isReady = false;
      }
      observer.disconnect();
      placeholder.remove();
    };
  }, []);

  return null;
};

export default TawkMessenger; 