// components/UrlForm.js
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useAuth } from './auth/AuthContext';
import UserMenu from './auth/UserMenu';
import AuthMenu from './auth/AuthMenu';
import '../css/UrlForm.css';

function UrlForm({ url, setUrl, handleSubmit, loading, className }) {
    const { user, loading: authLoading } = useAuth();
    const formClassName = `url-form-container ${className || ''}`.trim();

    return (
        <div className="url-section">
            <div className={formClassName}>
                <div className="header-section">
                    <Link to="/" className="brand-section">
                        <img 
                            src="/linksort_star.svg" 
                            alt="LinkSummarizer Logo" 
                            className="brand-logo"
                        />
                        <span className="brand-name">LinkSort.ai</span>
                    </Link>
                    {user ? <UserMenu /> : <AuthMenu />}
                </div>

                <div className="form-section">
                    <form onSubmit={handleSubmit} className="input-wrapper">
                        <input
                            type="url"
                            value={url}
                            onChange={(e) => setUrl(e.target.value)}
                            placeholder="Paste an article URL to generate its summary"
                            className="url-input"
                            required
                        />
                        <button 
                            type="submit" 
                            className={`summarize-btn ${loading ? 'loading' : ''}`}
                            disabled={loading}
                        >
                            {loading ? (
                                <span className="loading-text">
                                    <span className="loading-content">
                                        <span className="loading-spinner"></span>
                                        Please wait
                                    </span>
                                </span>
                            ) : (
                                'Summarize and Save'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}

UrlForm.propTypes = {
    url: PropTypes.string.isRequired,
    setUrl: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    className: PropTypes.string
};

export default UrlForm;